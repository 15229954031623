import {cloneDeep} from 'lodash';
import {bookingStatusType, IBooking, IManageBookingConfig} from "app/services/booking/booking.types";
import {IAppSettings, IVenue} from "app/models";
import {bookingAction} from 'app/services/route/route.types';
import {PaymentService} from "app/services/payment/payment.service";
import {servicePaymentType} from "shared-types/index";
import { MessageService } from 'app/services/message/message.service';

const NS = 'ManageBooking.helpers';

export const PHONE_LINK = '<a class="underline-phone" href="tel:{{phone}}">{{phone}}</a>';

export function bookingConfirmed(): IManageBookingConfig {
  return {
    hideDetails: true,
    hideButtons: true,
    title: 'Booking Confirmed',
    message: `Thank you, your booking has been confirmed. For any enquiries related to your booking please contact us on ${PHONE_LINK}.`
  };
}

export function bookingCancelled(venue: IVenue): IManageBookingConfig {
  return {
    hideDetails: true,
    hideButtons: true,
    title: 'Booking Cancelled',
    message: MessageService.getMessage(venue.widgetSettings.cancelledBookingMessage, venue, null),
  };
}

export function canCancelBooking(booking: IBooking, source: string, venue: IVenue): boolean {
  // Source : 1 = SMS   email : 2
  if ((source === '1' && venue.canCancelBookingBySms) ||
      (source === '2' && venue.canCustomersCancelBookings))
  {
    //Validation to check if current venue time is before booking time is already done in server side so removing it.
    return !booking.onlineCancelDisabled
  }

  return false;
}


export function canEditBooking(booking: IBooking, source: string, venue: IVenue, bookingTime: Date, venueTime: Date): boolean {
  // Source : 1 = SMS   email : 2
  if (!booking.onlineEditingDisabled && !booking.locked &&
    ((source === '1' && venue.canEditBookingBySms) ||
      (source === '2' && venue.canCustomersEditBookings))
  ) {

    //The server actually specifies this via onlineEditingDisabled field, so we don't need to do this check if AES is enabled
    if (!venue.widgetSettings.enableAdvancedEditingSupport) {
      if (booking.payment && booking.payment.amountPaid > 0 ||
        PaymentService.isPaymentPending(booking.status, booking.payment)
      ) {
        return false;
      }
    }

    const bookingTimeWithOffset: Date = cloneDeep(bookingTime);
    const offset: number = (venue.widgetSettings.disableEditingTimeWindow) ? venue.widgetSettings.disableEditingTimeWindow : 0;
    bookingTimeWithOffset.setHours(bookingTimeWithOffset.getHours() - offset);
    return (bookingTimeWithOffset > venueTime);
  }

  return false;
}

export function canConfirmBooking(booking: IBooking, bookingTime: Date, venueTime: Date): boolean {
  /**
   * If future booking show the confirm button
   *
   * Waitlist booking confirm link will auto expired after 15 mins
   * and allow confirm past booking
   */
  let canConfirm: boolean = bookingTime > venueTime || booking.status == bookingStatusType.partiallySeated;

  /**
   * NBI-1792: This should show the confirm button if the status is changed from 'Confirmed' to 'Unconfirmed' in the diary
   * after payment has been made (and not in payment pending).
   */
  if (PaymentService.checkForUnconfirmedAndPaid(booking)) {
    canConfirm = true;
  }

  /**
   * If any money is remaining to be paid (full, deposit or pre-auth) or status manually change in the diary to payment pending,
   * hides the confirm button.
   */
  if (PaymentService.isPaymentPending(booking.status, booking.payment)) {
    canConfirm = false;
  }

  return canConfirm;
}

export function getManageBookingConfig(booking: IBooking, appSettings: IAppSettings, venue: IVenue, bookingTime: Date, venueTime: Date): IManageBookingConfig {
  const displayMessage = (): string => {
    return venue.widgetSettings.enquiryMessage;
  }
  /**
   * Status is confirmed and payment has been taken.
   * In this case the person is unable to cancel or edit their booking, so we hide the buttons.
   * Doesn't matter what the status is - if it has been fully paid for, we don't allow to edit, confirm or cancel.
   * -----
   * For pre-auths the 'amountPaid' will be set to the 'amountDue' once authorized, but we still need to show the
   * cancel and confirm buttons since they can still cancel the booking and the venue may change the status to
   * unconfirmed manually.
   */
  if (booking.payment
    && booking.payment.paymentType !== servicePaymentType.noPayment
    && booking.payment.paymentType !== servicePaymentType.preAuth
    && booking.payment.price > 0
    && booking.payment.amountPaid && booking.payment.amountPaid >= booking.payment.price
  ) {

    return {
      ...bookingConfirmed(),
      hideDetails: false
    }
  }

  /**
   * Status is Cancelled
   */
  if (booking.status === bookingStatusType.cancelled) {
    return {
      ...bookingCancelled(venue),
      hideDetails: false
    };
  }


  /**
   * Confirmed status
   */
  if (booking.status === bookingStatusType.confirmed) {
    return {
      ...bookingConfirmed(),
      hideDetails: false,
      hideButtons: false
    }
  }

  /**
   * Trying to confirm
   */
  if (booking.status === bookingStatusType.unconfirmed && (
    appSettings.action === bookingAction.confirm ||
    appSettings.action === bookingAction.edit
  )) {
    const isFutureBooking: boolean = bookingTime > venueTime;
    // Trying to confirm
    if (isFutureBooking) {
      return {
        hideButtons: false,
        hideDetails: false,
        title: 'Booking Summary',
        message: displayMessage()
      }
    } else {
      // Confirm After Time
      return {
        hideButtons: true,
        title: 'Booking Started',
        message: 'Sorry, we can’t confirm your booking, it’s already started.',
        hideDetails: false
      }
    }
  }

  /**
   * Fall back for all else
   */
  return {
    hideButtons: false,
    hideDetails: false,
    title: 'Booking Summary',
    message: displayMessage()
  }
}

