import React, { ReactElement } from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Typography, Divider } from '@material-ui/core';
import { renderIf, UtilsService } from 'app/services/utils/utils.service';
import { noCase } from 'change-case';
import { IntlService } from 'app/services/intl/intlService';
import {servicePaymentType, themeTypes} from "shared-types/index";
import CustomizeDialog from 'app/components/CustomizeDialog/index';

const NS = 'PaymentMessage';

export default function PaymentMessage({
  paymentType, price, covers, currency, hasMenuOptions, bgColor, isPricePerPerson, dividePriceByCovers, preAuthorisationMessage, theme, originalPaymentDetails
}: IStateFromProps & IDispatchFromProps) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  const Price = ({calcPrice}: {calcPrice: number}): ReactElement => {
    return (
      <span className={isDark ? 'dark-text' : "secondary-text"}>
        {IntlService.currencyValue(calcPrice, currency)}
      </span>
    )
  }

  const fundsMessageBasedOnPriceChange = (currentPrice: number) => {
    if (originalPaymentDetails?.price === currentPrice) {
      return 'Funds will be verified again (with your previous verification cancelled), but not charged to your card at this time.';
    }
    return 'Funds will be verified, but not charged to your card at this time.'
  }

  const PRE_AUTH_TEXT = () => (
    <Typography variant="body1" data-testid="pre-auth-text">
      A credit card Booking Guarantee of&nbsp;
      <Price calcPrice={ hasMenuOptions || dividePriceByCovers || !isPricePerPerson? price : price * covers}/> is required to finalise
      your {UtilsService.getOrderType()}.&nbsp;
      {fundsMessageBasedOnPriceChange(hasMenuOptions || dividePriceByCovers || !isPricePerPerson ? price : price * covers)}
      {/*Change "View Booking Guarantee Policy" from open a new page to a popup--------*/}
      <CustomizeDialog name="View Booking Guarantee Policy" message={preAuthorisationMessage} isTC={false}/>
    </Typography>
  );


  return (
    <div>
      {renderIf(!paymentType || paymentType === servicePaymentType.noPayment, () => (<></>), () => (
        renderIf(hasMenuOptions, () => (
          renderIf(paymentType === servicePaymentType.preAuth,
            // show preauth text
            PRE_AUTH_TEXT, () => (
            // else not preauth
            <Typography variant="body1">
              A { noCase(paymentType) } for each booking option is required.
            </Typography>
          ))
        ), () => (
          renderIf(paymentType === servicePaymentType.functionPayment, () => (
            <Typography variant="body1" data-testid="function-text">
              A payment of <Price calcPrice={price} /> is required to finalise your {UtilsService.getOrderType()}.
            </Typography>
          ), () => (
            renderIf(paymentType === servicePaymentType.preAuth, PRE_AUTH_TEXT, () => (
              (() => {
                const calcPrice: number = (isPricePerPerson && dividePriceByCovers) ? (price/covers) : price;
                return (
                  <Typography variant="body1">
                    A { noCase(paymentType) } of <Price calcPrice={calcPrice} /> {isPricePerPerson ? 'per person ' : ''}is required to finalise your {UtilsService.getOrderType()}.
                  </Typography>
                );
              })()
            ))
          ))
        ))
      ))}
    </div>
  )
}
