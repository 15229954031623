import React from 'react';
import style from './style.module.scss';
import { Button } from '@material-ui/core';
import { paymentFooterNavTypes, IPaymentFooterNav } from './types';
import { renderIf } from 'shared-services/react-utils-service';
import classNames from 'classnames';
import { wrapperStyleType } from 'shared-types/index';
import { useFooterStyles } from 'shared-components/footer-nav';
import IframeResizerService from "shared-services/iframe-resizer-service";

const NS = 'PaymentFooterNav';

export default function PaymentFooterNav({theme, handleNavPressed, handleDisabledNextPressed, nextType, nextEnabled, prevEnabled, showCancel, wrapperStyle, isCancelChanges}: IPaymentFooterNav) {
  let nextText = 'Next';
  const cancelText = isCancelChanges === true ? 'Cancel Changes' : 'Cancel Booking';
  switch(nextType) {
    case paymentFooterNavTypes.pay:
      nextText = 'Make Payment';
      break;

    case paymentFooterNavTypes.preauth:
      nextText = 'Authorise';
      break;
  }

  const navBtnIsTightFit = wrapperStyle === wrapperStyleType.standard && prevEnabled;
  const navBtnIsChunky = IframeResizerService.isStacked(wrapperStyle);

  const classes = useFooterStyles(theme);

  return (
    <footer className={style.root}>

      {renderIf(prevEnabled, (
        <Button variant="contained" className={classNames({
          [classes.shadyButton]: true,
          [style.navBtn]: true,
          [style.navBtnIsTightFit]: navBtnIsTightFit
        })}
          onClick={() => handleNavPressed(paymentFooterNavTypes.prev)}>
          Back
        </Button>
      ))}

      {renderIf(showCancel, () => (
        <Button variant="contained" className={classNames({
            [style.navBtn]: true,
            [style.navBtnIsTightFit]: navBtnIsTightFit,
            [style.navBtnIsChunky]: navBtnIsChunky
          })}
          onClick={() => handleNavPressed(paymentFooterNavTypes.cancel)}>
          {cancelText}
        </Button>
      ), () => (
        // empty element just so flexbox pushes 'next' to end
        <div></div>
      ))}

      <div className={style.nextWrap} >
        {renderIf(!!handleDisabledNextPressed, () => (
          <Button className={classNames({
            [style.disabledNext]: true,
            [style.navBtn]: true,
            [style.nextBtnIsTightFit]: navBtnIsTightFit,
            [style.navBtnIsChunky]: navBtnIsChunky
          })} variant="contained" disabled={nextEnabled} color="default" onClick={handleDisabledNextPressed}>
            {nextText}
          </Button>
        ))}

        <Button className={classNames({
            [classes.nextButton]: true,
            [classes.shadyButton]: true,
            [style.nextBtn]: true,
            [style.nextBtnIsTightFit]: navBtnIsTightFit,
            [style.nextBtnIsChunky]: navBtnIsChunky
          })}
          variant="contained" disabled={!nextEnabled} color="secondary" onClick={() => handleNavPressed(nextType)}>
          {nextText}
        </Button>
      </div>
    </footer>
  )
}
