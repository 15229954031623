// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paper--U74xB{margin-left:20px;margin-right:20px;padding:15px;width:100%}.paperIsStacked--GT0sp{max-height:calc(100% - 16px);margin-left:8px;margin-right:8px;padding:12px 4px;width:100%}.btn--1qKP-{line-height:1.4}.btnWrap--N\\+WlF{padding:15px 0 0 0;justify-content:space-between}.btnWrapIsStacked--H6Kgi{padding:15px 8px 0;justify-content:space-between}.title--EUs1V{padding:5px 8px 5px}.titleIsStacked--rUQal{text-align:center}.content--FumFZ{padding:8px 5px;box-sizing:border-box}.content--FumFZ *{box-sizing:border-box}.contentInner--APU9d{transition:max-height 1s ease-in-out;max-height:800px;min-height:150px}.contentInnerIsStacked--wZDuP{max-height:none}.contentInner--APU9d.isLoading--pxpa\\+{max-height:100px;display:flex;justify-content:center;align-items:center}.explicitWrap--XJtPf{padding-top:20px}.explicitWrapIsStacked--nmrNq{padding-top:0}.implicitWrap--La137{padding-top:12px;padding-bottom:10px}.implicitWrapIsStacked--bJ099{padding-top:4px;padding-bottom:4px}.implicitHeader--jH4HY{margin-bottom:12px;font-size:2rem;text-align:center}.closeButton--k\\+Z5S{position:absolute;right:1px;top:1px;color:gray}.buttonRight--fGs7u{justify-content:flex-end}.box--0LHkH{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--0LHkH .boxItems--X520t{padding:8px;min-width:25%;max-width:25%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "paper--U74xB",
	"paperIsStacked": "paperIsStacked--GT0sp",
	"btn": "btn--1qKP-",
	"btnWrap": "btnWrap--N+WlF",
	"btnWrapIsStacked": "btnWrapIsStacked--H6Kgi",
	"title": "title--EUs1V",
	"titleIsStacked": "titleIsStacked--rUQal",
	"content": "content--FumFZ",
	"contentInner": "contentInner--APU9d",
	"contentInnerIsStacked": "contentInnerIsStacked--wZDuP",
	"isLoading": "isLoading--pxpa+",
	"explicitWrap": "explicitWrap--XJtPf",
	"explicitWrapIsStacked": "explicitWrapIsStacked--nmrNq",
	"implicitWrap": "implicitWrap--La137",
	"implicitWrapIsStacked": "implicitWrapIsStacked--bJ099",
	"implicitHeader": "implicitHeader--jH4HY",
	"closeButton": "closeButton--k+Z5S",
	"buttonRight": "buttonRight--fGs7u",
	"box": "box--0LHkH",
	"boxItems": "boxItems--X520t"
};
export default ___CSS_LOADER_EXPORT___;
