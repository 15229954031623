// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--Ixbqs{text-align:center;padding-top:5px}.listItem--UAmEz{text-align:center;padding:5px 10px}.listItemIsLandscape--tTRNr{padding:3px 10px}.list--e8FHX{padding:10px 25px 0}.listIsLandscape--vHP2V{overflow-y:auto;max-height:180px;-webkit-mask-image:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 23%, rgb(0, 0, 0) 100%);mask-image:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 23%, rgb(0, 0, 0) 100%);padding-bottom:15px}.heading---OjfQ{margin:0}.headingWrap--\\+Dm1N{padding:17px 0 14px;border-bottom:1px solid #dedede}.phone--CfMx5{margin:5px 0 0 0}.rightColumnInner--0bTV0{display:flex;flex-direction:column;justify-content:space-between;height:100%;padding-top:20px}.rightColumnInnerIsStacked--MsuAn{padding-top:0}.cardContent--mxMN4{padding:0 0 20px 0 !important}.msg--FWqHH{margin-bottom:13px}.alert--iXi5P{margin-top:12px;margin-bottom:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--Ixbqs",
	"listItem": "listItem--UAmEz",
	"listItemIsLandscape": "listItemIsLandscape--tTRNr",
	"list": "list--e8FHX",
	"listIsLandscape": "listIsLandscape--vHP2V",
	"heading": "heading---OjfQ",
	"headingWrap": "headingWrap--+Dm1N",
	"phone": "phone--CfMx5",
	"rightColumnInner": "rightColumnInner--0bTV0",
	"rightColumnInnerIsStacked": "rightColumnInnerIsStacked--MsuAn",
	"cardContent": "cardContent--mxMN4",
	"msg": "msg--FWqHH",
	"alert": "alert--iXi5P"
};
export default ___CSS_LOADER_EXPORT___;
