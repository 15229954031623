import { connect } from 'react-redux';
import ChildMenuOptionOverlay from 'shared-components/booking-options-section/ChildMenuOptionOverlay/index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps, IOwnProps } from 'shared-components/booking-options-section/ChildMenuOptionOverlay/types';
import { ThunkDispatch } from 'redux-thunk';
import {IVenue, IBookingMenuOptionExtrasUpdater, IWidgetTheme} from 'shared-types/index';
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";

const NS = 'ChildMenuOptionOverlayContainer';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps & IDispatchFromProps): IStateFromProps & IOwnProps => {
  const {theme, wrapperStyle, activeVenue, activeChildMenuOption} = widget;

  return {
    ...ownProps,
    wrapperStyle,
    theme: theme as IWidgetTheme,
    currency: activeVenue ? (activeVenue as unknown as IVenue).currency : null,
    menuOptionsImplicit: activeChildMenuOption ? activeChildMenuOption.menuOptionsImplicit : null,
    menuOptionsExplicit: activeChildMenuOption ? activeChildMenuOption.menuOptionsExplicit : null
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleDone: (data: IBookingMenuOptionExtrasUpdater) => {
      dispatch(BookingOptionsActionsNS.updateMenuOptionExtras(data));
    }
  }
};

const ChildMenuOptionOverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildMenuOptionOverlay as any);

export default ChildMenuOptionOverlayContainer;
