import {connect} from 'react-redux';
import StandbyConfirmPanel from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {NavigationActionsNS} from 'app/actions/navigation/navigationActions';
import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import { IDispatchFromProps, IStateFromProps } from './types';
import { footerNavTypes } from "app/components/FooterNav/types";

const NS = 'StandbyConfirmPanelContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  return {
    theme: widget.theme,
    wrapperStyle: widget.wrapperStyle,
    date: widget.standbyData.isFlexibleTime ? widget.activeService.times[0].time : widget.standbyData.utcTime,
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleNo: () => {
      dispatch(StandbyActionsNS.saveToStandbyList(true));
    },
    handleOk: () => {
      dispatch(NavigationActionsNS.navPressed(footerNavTypes.prev));
    },
  }
};

const StandbyConfirmPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StandbyConfirmPanel as any);

export default StandbyConfirmPanelContainer;
