import { ICustomer, themeTypes } from 'shared-types/index';

export interface IStringEvent {
  name: string;
  value: string;
}

export enum FlagPhonePickerStyle {
  diary = 'diary',
  abc = 'abc',
  widget = 'widget',
}

export interface IHandlers {}

export interface IProps {
  isPhoneIcon?: boolean;
  uiStyle?: FlagPhonePickerStyle;
  theme?: {
    type: themeTypes;
  };
  overrideStyles?: {
    focusedColor: string;
  };
  value: string;
  handleBlur: (e: any) => void;
  handleChange: (e: { target: IStringEvent }) => void;
  country: string;
  searchCustomer: (e: { target: IStringEvent }, countryCode?: string) => void;
  customers: ICustomer[];
  handleWholeFormChangeForAutocomplete: (value: ICustomer) => void;
  forceValidation: boolean;
  touched?: boolean;
  errors?: string;
  maxLength?: number;
  label?: string;
  forceTouched?: boolean;
  classPrefix?: string;
  skipAutoComplete?: boolean;
  usePlaceholderForLabel?: boolean;
  isDropDownListMoveUp?: boolean;
  disabled?: boolean; // for DM disable phone input with viewBooking
  name?: string
  required?: boolean
}

export interface IValidationHelperProps {
  customerCountry: string;
  customerPhone: string;
  customerSelected?: (customer: ICustomer) => void;
  customerSearch?: (phone: string, countryCode?: string) => Promise<ICustomer[]>;
  handlePhoneChange?: (phone: string, phoneWithoutPrefix?: string) => void;
  setPhoneValid?: (flag: boolean) => void;
  setPhoneTouched?: (flag: boolean) => void;
  setPhoneWithoutPrefix?: (phone: string) => void;

  validationError?: string;
  maxLength?: number;
  label?: string;
  forceTouched?: boolean;
  classPrefix?: string;
  skipAutoComplete?: boolean;
  usePlaceholderForLabel?: boolean;
  isDropDownListMoveUp?: boolean;
  disabled?: boolean; // for DM disable phone input with viewBooking
  overrideStyles?: IOverrideStyles;
  name?: string;
  uiStyle?: FlagPhonePickerStyle
}
export interface IOverrideStyles {
  focusedColor: string;
}
