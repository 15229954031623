import {connect} from 'react-redux';
import MenuOption from 'shared-components/booking-options-section/MenuOption';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IOwnProps, IStateFromProps} from 'shared-components/booking-options-section/MenuOption/types';
import {ThunkDispatch} from 'redux-thunk';
import {IMenuOptionsExtrasItem} from 'shared-components/booking-options-section/MenuOptionsExtras/types';
import {IVenue, IBookingMenuOption, loadStatus, servicePaymentType, IWidgetTheme, themeTypes} from 'shared-types/index';
import IframeResizerService from "shared-services/iframe-resizer-service";
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";

const NS = 'MenuOptionContainer';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IStateFromProps & IOwnProps => {
  const {wrapperStyle, theme, cachedMenuOptionDetails, booking, activeService} = widget;

  /**
   * Finds menuOption details if they exist in `cachedMenuOptionDetails` and combines them with quantity & currency
   */
  const getExtrasDetails = ({ menuOptionId, quantity }: IBookingMenuOption): IMenuOptionsExtrasItem => {
    const cachedOpt = cachedMenuOptionDetails.find(({id}) => menuOptionId === id);
    if (cachedOpt) {
      const {id, label, price, paymentType} = cachedOpt;
      return {id, label, price, paymentType, quantity, status: loadStatus.loaded };
    }

    // these will get loaded from backend and added to cachedMenuOptionDetails
    return {id: menuOptionId, label: null, price: null, paymentType: servicePaymentType.noPayment, quantity, status: loadStatus.idle };
  }

  const extraDetails: IMenuOptionsExtrasItem[] = [];
  if (booking.selectedMenuOptions) {
    booking.selectedMenuOptions.forEach(({menuOptionId, extras}) => {
      if (ownProps.id === menuOptionId && extras) {
        const { explicitChildMenuOptions, implicitChildMenuOptions } = extras;

        explicitChildMenuOptions.forEach(o1 => {
          o1.forEach(o2 => {
            extraDetails.push(getExtrasDetails(o2));
          });
        });

        implicitChildMenuOptions.forEach(o => {
          extraDetails.push(getExtrasDetails(o));
        });
      }
    });
  }

  const hasExtras = !!(ownProps.allChildMenuOptionIds && ownProps.allChildMenuOptionIds.length);

  let extrasHasPayment = hasExtras; // if children exist, assumes it has payment until checked with back end
  if (extrasHasPayment) {
    // gets details based on child ids, then if any child options have a payment, sets extrasHasPayment to true
    const details = ownProps.allChildMenuOptionIds.reduce((acc, id) => {
      const det = cachedMenuOptionDetails.find(o => o.id === id);
      if (det) {
        acc.push(det);
      }
      return acc;
    }, []);
    extrasHasPayment = details.some(o => o.paymentType !== servicePaymentType.noPayment)
  }

  let currencyValue: string = null;
  let bookingCancellationTimeWindowInHours: string = null;
  if (widget.activeVenue) {
    const {currency, widgetSettings} = widget.activeVenue as unknown as IVenue;
    currencyValue = currency;
    bookingCancellationTimeWindowInHours = widgetSettings.bookingCancellationWindow;
  }

  const hideLabel = activeService?.paymentDetails?.singleMenuPerBooking || false;

  const isStacked = IframeResizerService.isStacked(wrapperStyle);

  return {
    isStacked,
    theme: theme as IWidgetTheme,
    currency: currencyValue,
    bookingCancellationTimeWindowInHours,
    extraDetails,
    extrasHasPayment,
    ...ownProps,
    allChildMenuOptionIds: ownProps.allChildMenuOptionIds.filter(id => !cachedMenuOptionDetails.some(o => o.id === id)),
    hideLabel,
    pricingType: activeService?.paymentDetails?.pricingType || null,
  };
};




/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleIdleCachedMenuOptionDetails: (extraDetails: IMenuOptionsExtrasItem[]) => {
      const ids: string[] = [];
      extraDetails.forEach(o => {
        if (o.status === loadStatus.idle) {
          ids.push(o.id);
        }
      });
      if (ids.length) { // @todo: figure out if this actually ever gets called. severity: low
        dispatch(BookingOptionsActionsNS.updateCachedMenuOptionDetails(ids));
      }
    },
    updateCachedMenuOptionDetails: (ids: string[]) => {
      if (ids.length) {
        return dispatch(BookingOptionsActionsNS.updateCachedMenuOptionDetails(ids));
      }
      return Promise.reject();
    }
  }
};

const MenuOptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuOption as any);

export default MenuOptionContainer;
