// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--JVQ5f{padding:10px 0 4px}.labelText--E2PkB{font-size:.7rem;margin-right:0}.alert--hY8Hx{margin-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--JVQ5f",
	"labelText": "labelText--E2PkB",
	"alert": "alert--hY8Hx"
};
export default ___CSS_LOADER_EXPORT___;
