// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--mogSQ{padding:0 0 15px;display:flex}.rootIsTightFit--pwKGc{display:block}.text--mnLvf{flex-grow:1}.textIsTightFit--qSCAH{width:100%}.btnWrap--Vijnn{display:flex;flex-direction:column;justify-content:flex-end;padding:0 0 3px 15px}.btnWrapIsTightFit--SF4A2{padding-left:0;margin-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--mogSQ",
	"rootIsTightFit": "rootIsTightFit--pwKGc",
	"text": "text--mnLvf",
	"textIsTightFit": "textIsTightFit--qSCAH",
	"btnWrap": "btnWrap--Vijnn",
	"btnWrapIsTightFit": "btnWrapIsTightFit--SF4A2"
};
export default ___CSS_LOADER_EXPORT___;
