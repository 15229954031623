// remove this when you no longer need to support ie11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {AppLoadService} from 'app/services/appLoad.service';
import ReduxStoreContainer from './containers/App/ReduxStoreContainer';

const NS = 'main';

// Hot Module Replacement
declare let module: { hot: any };

if (!AppLoadService.redirectOnNoAccountId()) {

  ReactDOM.render(
    <ReduxStoreContainer />,
    document.getElementById('root')
  );

  if (module.hot) {
    module.hot.accept("./containers/App/ReduxStoreContainer", () => {
      const NewContainer = require("./containers/App/ReduxStoreContainer").default;

      ReactDOM.render(
        <NewContainer />,
        document.getElementById("root"));
    });
  }

}

