import { Dispatch } from "redux";
import {
  first,
} from 'rxjs/operators';
import { IVenue } from "app/models";
import { ClientService } from "app/services/client/client.service";
import {IActionGen, loadStatus} from "app/types/common.types";
import {
  ISavedBooking,
  IBookingMenuOption,
} from "app/services/booking/booking.types";
import { SetupActionsNS } from "../setup/setupActions";
import { SessionService } from "app/services/session/session.service";
import { appLoadCompleteSuccess } from "../setup/helpers";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import { ISchedule } from "app/services/client/client.types";
import { IErrorResponse } from "shared-types/index";
import { IManageBookingFail } from "./interfaces";

const NS = 'BookingActions';

export function loadScheduleForSavedBooking(
  dispatch: Dispatch, activeVenue: IVenue, savedBooking: ISavedBooking
): Promise<ISchedule> {

  return new Promise((resolve, reject) => {

    dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});

    dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
      completeLoadStatus: false,
      status: loadStatus.loading
    }} as SetupActionsNS.IAppLoadComplete);

    // on back end, when providing bookingId, it will remove the booking from the bookings list, so that it can become available again
    const bookingId: string = savedBooking.bookingId;

    ClientService.getSchedule(savedBooking.moment, savedBooking.covers, (activeVenue as IVenue).id, bookingId)
      .pipe(first())
      .subscribe((value: ISchedule) => {
        dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS, payload: value} as IActionGen<ISchedule>);
        appLoadCompleteSuccess(dispatch);
        resolve(value);
      }, ({response}: {response: IErrorResponse}) => {
        console.warn(NS, 'loadScheduleForSavedBooking', 'error', response)
        // dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_FAILED} as IAction);
        reject(response);
      });
  });
}

export function bookingNotFoundDispatch(dispatch: Dispatch): void {
  dispatch({type: BookingActionsTypes.EDIT_BOOKING_FAIL, payload: {
    title: 'Link Expired',
    message: 'Please contact us on <a class="underline-phone" href="tel:{{phone}}">{{phone}}</a>.'
  }} as IManageBookingFail);
  appLoadCompleteSuccess(dispatch);
}

export function finishSession(dispatch: Dispatch): void {
  dispatch({type: BookingActionsTypes.SESSION_FINISHED});
  SessionService.stopSession(false);
}

/**
 * returns flat list of ids for all menu options and child menu options
 */
export function getMenuOptionIds(selectedMenuOptions: IBookingMenuOption[]): string[] {
  return selectedMenuOptions.reduce((acc, {menuOptionId, extras}) => {
    acc.push(menuOptionId);
    if (extras) {
      if (extras.explicitChildMenuOptions) {
        extras.explicitChildMenuOptions.forEach(opts => {
          opts.forEach(o => acc.push(o.menuOptionId));
        });
      }
      if (extras.implicitChildMenuOptions) {
        extras.implicitChildMenuOptions.forEach(o => acc.push(o.menuOptionId));
      }
    }
    return acc;
  }, []);
}


export function hideUpsellPopup(dispatch: Dispatch, hideUpsell = false): void {
  dispatch({
    type: BookingActionsTypes.HIDE_UPSELL_POPUP, payload: {
      hideUpsell
    }
  } as IActionGen<{ hideUpsell: boolean }>);
}
