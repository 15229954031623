import React from 'react';
import { IMenuOptionsRadio } from './types';
import style from './style.module.scss';
import { IBookingMenuOption, IServicePaymentOption } from 'shared-types/index';
import { FormControl, Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import MenuOptionContainer from 'internal-components/MenuOption/container';
import BookingOptionsHelpers from "shared-components/booking-options-section/helpers";
import CustomRadio from "./CustomRadio";

const NS = 'MenuOptionsRadio';


export default function MenuOptionsRadio({
  selectedMenuOptions, menuOptions,
  type, gridXs, gridMd, gridGutter,
  handleRadioSelect, handleOpenExtras
}: IMenuOptionsRadio) {

  const selectedOpt: IBookingMenuOption = selectedMenuOptions.find(o => o.quantity > 0);

  let inputTO: any;

  const scrollGridItemIntoView = (itemEl: HTMLDivElement) => {
    if (itemEl.scrollIntoView) {
      if (inputTO) {
        clearTimeout(inputTO);
      }
      inputTO = setTimeout(() => {
        itemEl.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  const useGrid = !!gridGutter;

  return (
    <FormControl component="fieldset" className={style.radWrap}>
      <RadioGroup aria-label="services" name="services" data-testid="radio-btn-val"
        value={selectedOpt ? selectedOpt.menuOptionId : ''}
        onChange={(evt, id) => handleRadioSelect(id)}
        className={style.radGrp}>

        {(() => ( // Grid Outer wrapper
          BookingOptionsHelpers.muiGridOrCustomElementOuter(useGrid, "", style.box, gridGutter, (
            <>
              {menuOptions.map((opts: IServicePaymentOption, i: number) => {
                const {
                  id, price, label, link, description, childMenuOptionIds,
                  explicitChildMenuOptionIds, paymentType, paymentTypeOverride
                } = opts;
                const gridItemRef: React.RefObject<HTMLDivElement> = React.createRef(); // ref used for scrolling
                const gridSizes = {xs: gridXs, sm: false, md: gridMd, lg: false};

                const allChildMenuOptionIds: string[] = (childMenuOptionIds || []).concat(explicitChildMenuOptionIds || []);
                const hasChildMenuOptions: boolean = allChildMenuOptionIds.length > 0;
                const _handleOpenExtras = selectedOpt && selectedOpt.menuOptionId === id && hasChildMenuOptions ? () => handleOpenExtras(i) : null;

                // Grid Inner Items
                return (
                  BookingOptionsHelpers.muiGridOrCustomElementInner(id, useGrid, gridSizes, ""  , style.boxItems, gridItemRef, (
                    <MenuOptionContainer
                      id={id}
                      paymentType={paymentTypeOverride ? paymentTypeOverride.paymentType : paymentType}
                      paymentTypeError={paymentTypeOverride ? paymentTypeOverride.hasError : false}
                      price={price}
                      label={label}
                      description={description}
                      link={link}
                      type={type}
                      hideLabel={true}
                      handleOpenExtras={_handleOpenExtras}
                      allChildMenuOptionIds={allChildMenuOptionIds}>
                      <CustomRadio id={id} label={label} checkedCB={() => {
                        scrollGridItemIntoView(gridItemRef.current);
                      }} />
                    </MenuOptionContainer>
                  ))
                )
              })}
            </>
          ))
        ))()}
      </RadioGroup>
    </FormControl>
  )
}
