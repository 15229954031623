// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--FqJui{flex-grow:1;text-align:center;height:100%;background-color:#f3f3f3 !important;padding:12px 11px 13px}.rootIsDark--9STjc{background-color:rgba(255,255,255,.11) !important}.rootHasCb--wxXDa{padding:16px 4px}.root--FqJui>*{margin-top:8px}.root--FqJui>*:first-child{margin-top:2px}.contentWrap--f82X5{display:flex;justify-content:center}.price--83wXj{margin-top:7px;margin-bottom:8px;font-size:2rem !important}.priceHasCb--lynyH{margin-top:3px;margin-bottom:4px}.link--IZBw5{word-break:break-all}.linkHasCb--OOJTC{margin-top:0;margin-bottom:4px}.label--St5Tv pre,.description--w1coC pre{white-space:pre-wrap}.description--w1coC,.paymentText--JrgrX{font-size:1.3rem;line-height:1.3}.label--St5Tv{font-size:1.6rem;line-height:1.3}.labelHasCb--oCW7x{line-height:1.2;position:relative}.labelIsStacked--iOO\\+B{font-size:1.5rem}.description--w1coC{margin-top:12px !important;min-height:2rem;max-height:105px;overflow-y:auto;display:block}.descriptionHasCb--rd9eR{margin-top:0}.description--w1coC ul{text-align:left;padding-left:30px}.description--w1coC p{margin:2px 0 0}.descriptionDesignV2--uiwKi{color:#858589}.checkbox--gsnVT{padding:0}.designV2Container--n69XT{background-color:#fff;display:flex;flex-direction:column;padding:10px;border:1px #fff solid}.designV2Description--BGgXZ{display:flex;flex-direction:column}.designV2Children--liof\\+{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.designV2ChildrenRight--xXhX2{display:flex;flex-direction:column;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--FqJui",
	"rootIsDark": "rootIsDark--9STjc",
	"rootHasCb": "rootHasCb--wxXDa",
	"contentWrap": "contentWrap--f82X5",
	"price": "price--83wXj",
	"priceHasCb": "priceHasCb--lynyH",
	"link": "link--IZBw5",
	"linkHasCb": "linkHasCb--OOJTC",
	"label": "label--St5Tv",
	"description": "description--w1coC",
	"paymentText": "paymentText--JrgrX",
	"labelHasCb": "labelHasCb--oCW7x",
	"labelIsStacked": "labelIsStacked--iOO+B",
	"descriptionHasCb": "descriptionHasCb--rd9eR",
	"descriptionDesignV2": "descriptionDesignV2--uiwKi",
	"checkbox": "checkbox--gsnVT",
	"designV2Container": "designV2Container--n69XT",
	"designV2Description": "designV2Description--BGgXZ",
	"designV2Children": "designV2Children--liof+",
	"designV2ChildrenRight": "designV2ChildrenRight--xXhX2"
};
export default ___CSS_LOADER_EXPORT___;
