// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radWrap--ibwnq{display:block;margin-bottom:5px}.radGrp--0PgkM{display:block}.radBtn---d7s4{padding:3px 5px;order:2;margin-top:7px}.radLbl--JXO2E{display:flex;flex-direction:column;align-items:center;margin:0}.radLbl--JXO2E span{font-size:1.6rem}.validationMsg--udg7w{margin-top:15px}.validationMsgIsCentered--koDF3{text-align:center}.counterMessage--gj3-0{display:flex;justify-content:space-between;align-items:center}.box--dMIfw{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--dMIfw .boxItems--mzVfr{padding:8px;min-width:25%;max-width:25%;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radWrap": "radWrap--ibwnq",
	"radGrp": "radGrp--0PgkM",
	"radBtn": "radBtn---d7s4",
	"radLbl": "radLbl--JXO2E",
	"validationMsg": "validationMsg--udg7w",
	"validationMsgIsCentered": "validationMsgIsCentered--koDF3",
	"counterMessage": "counterMessage--gj3-0",
	"box": "box--dMIfw",
	"boxItems": "boxItems--mzVfr"
};
export default ___CSS_LOADER_EXPORT___;
