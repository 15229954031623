import React, { useEffect, useMemo, useState } from 'react';
import { ISectionSelector } from './types';
import style from './style.module.scss';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import classNames from 'classnames';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import {TimeFilterService} from "app/services/timeFilter/timeFilter.service";
import {ISectionOrder} from "shared-types/index";
import { ISelectableTime } from "app/components/TimePicker/types";
import {ThemeColorsService} from "app/services/theme/themeColors.service";
import {SimplePaletteColorOptions} from "@material-ui/core/styles/createPalette";
import {themeTypes} from "app/models";
import {cloneDeep} from "lodash";
import {SectionsAvailableService} from "app/services/sectionsAvailable/sectionsAvailable.service";

const NS = 'SectionSelector';

const theme = ThemeColorsService.getThemeDefaults();

export default function SectionSelector({
  activeService, sections, activeSectionId, wrapperStyle, theme, handleSelect, isStandbyMode
}: ISectionSelector) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const [availableSections, setAvailableSections] = useState<Map<string, boolean>>();
  // Means: Whole Section is blocked
  const [blockoutSections, setBlockoutSections] = useState<Set<string>>();

  useEffect(() => {
    if(activeService) {
      const _availableSections = SectionsAvailableService.getAvailableSectionsMap(activeService.times, sections, isStandbyMode);
      const _blockoutSections = SectionsAvailableService.getBlockOutSectionsSet(activeService.times, sections);
      setAvailableSections(_availableSections);
      setBlockoutSections(_blockoutSections);
    }
  }, [activeService])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    handleSelect(id);
  };

  const isStacked = IframeResizerService.isStacked(wrapperStyle);

  return (
    <FormControl component="fieldset" className={classNames({
        [style.radioWrapIsStacked]: isStacked
      })}>
      <RadioGroup aria-label="sections" name="sections"
        value={activeSectionId && availableSections?.get(activeSectionId) ? activeSectionId : ''}
        onChange={handleChange}
        className={classNames({
          [style.radGrp]: true,
          [style.radGrpIsStacked]: isStacked
        })}>
         {sections
          .filter((s) => {
            if (!blockoutSections) return true;

            return !blockoutSections.has(s.id);
          })
          .map((s, i) => {
            const hasAvailableSection: boolean = availableSections && availableSections.get(s.id);
            return (
              <div key={i}>
                <FormControlLabel
                  className={classNames({
                    [style.radLbl]: true,
                    [style.radLblIsStacked]: isStacked
                  })}
                  value={s.id}
                  label={s.name}
                  disabled={isStandbyMode ? false : !hasAvailableSection}
                  control={
                    <Radio color="secondary" className={style.radBtn} />
                  } />
                {!isStandbyMode && !hasAvailableSection &&
                  <div
                    data-testid={"section-selector-test-id-" + i.toString()}
                    className={classNames({
                      [style.redError]: true,
                    })}
                    style={{
                      color: (theme.palette.error as SimplePaletteColorOptions)[isDark ? 'light' : 'main']
                    }}
                  >No tables available</div>}

              </div>
            )
          })}
      </RadioGroup>
    </FormControl>
  )
}
