import React, { Fragment } from 'react';
import style from '../Summary/style.module.scss';
import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { wrapperStyleType } from 'app/models';
import { IDisplayBookedByInfoProps } from 'app/components/DisplayBookedByInfo/types';
import { IBookedByCustomerDetail } from 'shared-types'

const DisplayBookedByInfo = ({bookedBy, isStacked, wrapperStyle, title ='Confirm your booked by details', subTitle, containerStyle, isSummaryTable, isDark}: IDisplayBookedByInfoProps) => {
  const parseKey = {
    firstName: 'First Name',
    lastName: 'Surname',
    company: 'Company Name',
    phone: 'Mobile',
    email: 'Email',
  } as { [key: string]: string };
  const getBookedByDisplayInfo = (bookedBy: IBookedByCustomerDetail) => {
    const infoList = ['firstName', 'lastName', 'company', 'phone', 'email'];
    return infoList.filter((key) => !!bookedBy[key as keyof IBookedByCustomerDetail])
  }
  return (
      <div style={{...containerStyle}}>
        <Typography variant="h2" className={style.detailsTitle}>
          {title}
        </Typography>
        {!!subTitle && <Typography className={classNames({
                 [style.summaryBookedByTips]: true,
                 [style.summaryBookedByTipsColor]: !isDark,
               })}>
          {subTitle}
        </Typography>}
        <Table aria-label="summary" size="small" x-ms-format-detection="none"
               className={classNames({
                 [style.summaryTable]: isSummaryTable,
                 [style.summaryTableIsStacked]: isSummaryTable && isStacked,
                 [style.summaryTableIsNarrow]: isSummaryTable && wrapperStyle === wrapperStyleType.narrow,
                 [style.summaryTableIsStandard]: isSummaryTable && wrapperStyle === wrapperStyleType.standard
               })}
        >
          <TableBody>
            {getBookedByDisplayInfo(bookedBy).map(key => (
              <TableRow key={key} data-testid="summary-detail-row">
                <TableCell data-testid={`key-${key}`}>{parseKey[key]}</TableCell>
                <TableCell data-testid={`value-${key}`}>{bookedBy[key as keyof IBookedByCustomerDetail]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
  );
}

export default DisplayBookedByInfo;
