import {IPromoCodeFail, IPromoCodeVerified} from "app/actions/booking/interfaces";
import {IVenue, IWidgetModel} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {ErrorService} from "app/services/error/error.service";
import {IActionGen} from "app/types/common.types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";

const NS = 'promoCodeReducer';

export default {
  [BookingActionsTypes.PROMOTION_CODE_VERIFIED]: (state: IWidgetModel, action: IPromoCodeVerified): IWidgetModel => {

    const booking: IBooking = {
      ...state.booking,
      payment: {
        ...state.booking.payment,
        amountDue: action.payload.amountDue,
        discountAmount: action.payload.discountAmount,
        discountApplied: true
      }
    };
    return {
      ...state,
      booking
    }
  },

  [BookingActionsTypes.PROMOTION_CODE_FAIL]: (state: IWidgetModel, action: IPromoCodeFail): IWidgetModel => {

    const bookingError = action.payload.errorType ? ErrorService.getBookingErrorFromType(action.payload.errorType, state.activeVenue as IVenue) : null;

    return {
      ...state,
      bookingError,
    }
  },

  [BookingActionsTypes.UPDATE_HAS_PROMOTION_CODE]: (state: IWidgetModel, action: IActionGen<boolean>): IWidgetModel => {

    return {
      ...state,
      hasPromoCodes: action.payload
    }
  },
}
