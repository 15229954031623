import {Provider} from "react-redux";
import * as React from "react";
import AppContainer from "app/containers/App/container";
import {RouteService} from "app/services/route/route.service";
import {states} from "app/services/route/route.states";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {UIRouter, UIRouterReact} from "@uirouter/react";
import {applyMiddleware, createStore, Store} from "redux";
import {IRootState, rootReducer} from "app/reducers";
import {logger} from "app/middleware";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

function configureStore(): Store<IRootState> {
  let middleware = applyMiddleware(logger, thunk);

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(rootReducer, undefined, middleware) as Store<IRootState>;

  /**
   * By default HMR doesn’t know how to update the reducers and whenever there is a change in some of the reducers
   * all of them get reset to initial state. This snippet allows hot reload to work with reducers and preserve their state.
   * (It replaces currently used by the store reducer to calculate the state.)
   */
  if (module.hot) {
    module.hot.accept('app/reducers', () => {
      const nextReducer = require('app/reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}


const router: UIRouterReact = RouteService.getRouter();
states.forEach(state => router.stateRegistry.register(state));
router.urlRouter.otherwise({state: ROUTE_NAMES.SETUP});

// prepare store
const store = configureStore();

export default function ReduxStoreContainer() {
  return (
    <Provider store={store}>
      <UIRouter router={router} >
        <AppContainer />
      </UIRouter>
    </Provider>
  )
}
