import { Checkbox, Grid, Paper, Typography } from "@material-ui/core";
import classNames from "classnames";
import style from "./menuOptionWide.style.module.scss";
import UtilsService from "shared-services/utils-service/index";
import {renderIf} from "shared-services/react-utils-service/index";
import React from "react";
import {IMenuOption} from "../types";
import {MenuOptionPaymentType} from "../paymentType/menuOptionPaymentType";
import {servicePaymentType} from "shared-types/index";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuOptionsExtras from 'shared-components/booking-options-section/MenuOptionsExtras';


// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

export const MenuOptionWide: React.FC<IMenuOption> = ({
  isDark, hasCb, extrasHasPayment, bgColor, isStacked, theme, currencyValue, hideLabel,
  children, description, label, paymentType, pricingType, price, paymentTypeError,
  bookingCancellationTimeWindowInHours, hoursText, isDesignV2
}: IMenuOption & { children?: any }) => {


  const Subtitle = () => (
    <>
      {renderIf(label && !hideLabel, (
        <Typography
          data-testid={`label${isDesignV2 ? '-designV2' : ''}`}
          variant="subtitle1"
          className={classNames({
            [style.label]: true,
            [style.labelHasCb]: hasCb,
            [style.labelIsStacked]: isStacked
          })}>
          <ReactMarkdown source={label}
                         renderers={{
                           paragraph: 'span'
                         }}
                         escapeHtml={false}/>
        </Typography>
      ))}
    </>
  );


  const Description = () => (
    <>
      {renderIf(description, (
        // using span because could contain 'ul'/'ol' elements, which can't be nested in 'p'
        <Typography
          data-testid={`description${isDesignV2 ? '-designV2' : ''}`}
          component="span" variant="body1" className={classNames({
          [style.description]: true,
          [style.descriptionDesignV2]: isDesignV2,
          [style.descriptionHasCb]: hasCb,
        })}>
          <ReactMarkdown source={description}
                         renderers={{
                           link: UtilsService.reactMarkDownBlankTargets
                         }}
                         escapeHtml={false}/>
        </Typography>
      ))}
    </>
  );

  const PaymentArea = () => (
    <>
      {renderIf(price && paymentType !== servicePaymentType.preAuth, (
        <Typography variant="h4" className={classNames({
          [style.price]: true,
          [style.priceHasCb]: hasCb
        })}>
          {currencyValue}

          <MenuOptionPaymentType theme={theme} extrasHasPayment={extrasHasPayment} bgColor={bgColor}
                                 paymentTypeError={paymentTypeError} paymentType={paymentType}
                                 currencyValue={currencyValue} hoursText={hoursText} pricingType={pricingType}
                                 bookingCancellationTimeWindowInHours={bookingCancellationTimeWindowInHours}/>
        </Typography>
      ))}
    </>
  );

  if (isDesignV2) {
    return (
      <div className={classNames({
        [style.designV2Container]: true,
      })}>
        <div className={classNames({
          [style.designV2Description]: true,
        })}>
          <Grid container item xs={12} sm={4}>
            <Subtitle />
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Description />
          </Grid>

        </div>
        <div className={classNames({
          [style.designV2Children]: true,
        })}>
          <Grid container item xs={12} sm={1}>
            <PaymentArea />
          </Grid>
          <div className={style.contentWrap}>
            {children}
          </div>
        </div>
      </div>
    )
  }
  return (
    <Paper elevation={2} className={classNames({
      [style.root]: true,
      [style.rootIsDark]: isDark,
      [style.rootHasCb]: hasCb
    })}>
      <Grid data-testid="upsell" container spacing={3}>
        <Grid container item xs={12} sm={4}>
          <Subtitle />
        </Grid>
        <Grid container item xs={12} sm={4}>
          <Description />
        </Grid>
        <Grid container item xs={12} sm={1}>
          <PaymentArea />
        </Grid>
        <Grid container item xs={12} sm={1}>
          <div className={style.contentWrap}>
            {children}
          </div>
        </Grid>
      </Grid>

    </Paper>
  )
}
