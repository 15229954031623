import React, { ReactElement } from 'react';
import { IMenuOptionSummary, lookType } from './types';
import style from './style.module.scss';
import { Table, TableRow, TableBody, TableCell } from '@material-ui/core';
import { IntlService } from 'app/services/intl/intlService';
import classNames from 'classnames';
import { themeTypes } from 'app/models';

const NS = 'MenuOptionSummary';

export default function MenuOptionSummary({theme, menuOptions, currency, look}: IMenuOptionSummary) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const isMBLook = look === lookType.manageBooking;

  const Price = ({val, isColored}: {val: number, isColored: boolean}): ReactElement => {
    return (
      <span className={isColored ? "secondary-text" : ''}>
        {IntlService.currencyValue(val, currency)}
      </span>
    )
  };

  /**
   * can't wrap in a div or any other element because this component is designed to sit inside a table,
   * which only allows certain element types
   */
  return (
	<>

	  {menuOptions && menuOptions.reduce((acc, item, i) => {
        /**
         * Needed to expand this loop due to unique key and tables not allowing elements around <tr>
         */

        if (item.quantity > 0) {
          const childLineItems = item.childLineItems?.length ? item.childLineItems.filter(item => item.quantity > 0) : null;

          const items = [
            <TableRow key={`menu-opt-${i}`}
              data-testid="menu-option-summary-item"
              className={classNames({
                [style.row]: true,
                [style.rowIsMbLook]: isMBLook,
                [style.rowIsMbLookIsDark]: isMBLook && isDark,
                [style.tableRowHasChildren]: childLineItems && childLineItems.length
              })}
            >
              <TableCell>{item.label}:</TableCell>
              <TableCell align="right">
                <Price val={item.price} isColored={false} /> x {item.quantity}
              </TableCell>
            </TableRow>
          ]

          if (childLineItems) {
            childLineItems.forEach((childItem, j) => {
              items.push(
                <TableRow key={`menu-opt-${i}-${j}`}
                  data-testid="menu-option-summary-children-item"
                  className={classNames({
                    [style.row]: true,
                    [style.rowIsMbLook]: isMBLook,
                    [style.rowIsMbLookIsDark]: isMBLook && isDark,
                    [style.tableRowChild]: true,
                    [style.tableRowChildIsDark]: isDark,
                    [style.tableRowLastChild]: j === item.childLineItems.length-1
                  })}
                  >
                  <TableCell>{childItem.label}:</TableCell>
                  <TableCell align="right">
                    <Price val={childItem.price} isColored={false} /> x {childItem.quantity}
                  </TableCell>
                </TableRow>
              );
            });
          }

          acc.push(items);
        }

        return acc;
      }, [])}

	</>
  )
}
