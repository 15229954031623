import React, { useState } from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import PolicyAggreemment from '../PolicyAggreemment';
import CustomerDetailsFormContainer from '../CustomerDetailsForm/container';

const NS = 'CustomerDetails';

export default function CustomerDetails({
    wrapperStyle, policyAgreementText, venueName
  }: IStateFromProps & IDispatchFromProps) {

  const [showPolicyAg, setShowPolicyAg] = useState<boolean>(!!policyAgreementText);

  const handleAcceptPolicyAg = () => {
    setShowPolicyAg(false);
  };

  return (
    <div data-testid="container">
      <CustomerDetailsFormContainer />
      <PolicyAggreemment
        data-testid="policy-agreement"
        wrapperStyle={wrapperStyle} policyAgreementText={policyAgreementText} venueName={venueName}
        doShow={showPolicyAg} handleClose={handleAcceptPolicyAg}
      />
    </div>
  )
}

