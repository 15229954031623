
import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import SimplePage from 'shared-components/simple-page/index';

const NS = 'ErrorPage';


export default function ErrorPage({ theme, wrapperStyle, bookingError, handleReset }: IStateFromProps & IDispatchFromProps) {

  return (
    <SimplePage
      theme={theme}
      title={bookingError ? bookingError.heading : ''}
      body={bookingError ? bookingError.message : ''}
      buttonText={bookingError ? bookingError.buttonText : ''}
      bodyIsMarkDown={true}
      buttonCallback={() => {
        if (bookingError) {
          handleReset(bookingError.messageType, bookingError.name);
        }
      }}
      centerButton={!!bookingError}
    />
  )
}

