// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--P\\+Zm5{display:flex;flex-direction:column}.alertWrap---otJ3{margin-bottom:10px}.btnWrap--CXclg{display:flex;justify-content:space-between}.btnWrapIsCentered--czk0b{justify-content:center}.btnWrapIsStacked--rImy\\+{padding-top:7px}.disabledNext--c5SI2{opacity:0;position:absolute;box-shadow:none;right:0}.nextWrap--WfQFG{position:relative}.navBtn--viisK{line-height:1.3;padding:8px 15px;font-size:1.5rem;margin:8px}.navBtnIsChunky--ygCDw{font-size:1.6rem}.nextLoader--Q0gJD{position:absolute;z-index:10;left:50%;top:50%;margin-left:-10px;margin-top:-10px}.nextTextIsLoading--OniyI{opacity:.4}.leftSpace--MrCoZ{margin-left:10px}.isNarrow--6yG0z{display:flex;flex-grow:1;flex-direction:row;justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--P+Zm5",
	"alertWrap": "alertWrap---otJ3",
	"btnWrap": "btnWrap--CXclg",
	"btnWrapIsCentered": "btnWrapIsCentered--czk0b",
	"btnWrapIsStacked": "btnWrapIsStacked--rImy+",
	"disabledNext": "disabledNext--c5SI2",
	"nextWrap": "nextWrap--WfQFG",
	"navBtn": "navBtn--viisK",
	"navBtnIsChunky": "navBtnIsChunky--ygCDw",
	"nextLoader": "nextLoader--Q0gJD",
	"nextTextIsLoading": "nextTextIsLoading--OniyI",
	"leftSpace": "leftSpace--MrCoZ",
	"isNarrow": "isNarrow--6yG0z"
};
export default ___CSS_LOADER_EXPORT___;
