import {connect} from 'react-redux';
import GroupAvailabilityWidget from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {BookingActionsNS} from "app/actions/booking/bookingActions";
import { sortBy } from 'lodash';

const NS = 'GroupAvailabilityWidgetContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {
    allVenueSchedule, activeService, selectedTimeForOtherVenues, accountDetails, allVenueApiCall
  } = widget;

  return {
    wrapperStyle: widget.wrapperStyle,
    theme: widget.theme,
    allVenueSchedule: sortBy(allVenueSchedule, 'displayOrder'),
    activeService,
    selectedTimeForOtherVenues,
    venueList: accountDetails.ownedVenues,
    allVenueApiCall
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleTimeSelection: (schedule, time) => {
      dispatch(BookingActionsNS.redirectToOtherVenue(schedule, time));
    }
  }
};

const GroupAvailabilityWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAvailabilityWidget as any);

export default GroupAvailabilityWidgetContainer;
