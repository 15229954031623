import React, { useEffect, useMemo, forwardRef, useImperativeHandle, Ref } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from './style.module.scss';
import { Divider, Theme } from '@material-ui/core';
import { ICustomizeDialog, ICustomizeDialogRef } from 'app/components/CustomizeDialog/types';
import { isEmpty } from 'lodash';


// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');
const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const BasicButton = withStyles(() => ({
  root: {
    ['&:hover']: {
      backgroundColor: 'transparent',
    },
    paddingLeft: 0,
  }
}), { name: 'BasicButton' })(Button);
const DialogTitle: any = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other }: any = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Dialog = withStyles((theme) => {
  const { palette: { type, common, text } } = theme
  return {
    root: {
      "& .MuiPaper-root": {
        backgroundColor: type === 'light' ? common.white : common.black,
      },
      "& a": {
        color: text.secondary
      }
    }
}})(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    lineHeight: '1.5',
  },
}))(MuiDialogContent);


function CustomizeDialog(props: ICustomizeDialog, ref: Ref<ICustomizeDialogRef>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isPrivacyPolicyEmpty = isEmpty(props.privacyPolicyURL);

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const renderName =  useMemo(() => {
    const { customName, name } = props

    if (customName) {
      return customName
    }

    return (
      <BasicButton onClick={handleClickOpen}>
        <span className={style.underlineOfText}>{name}</span>
      </BasicButton>
    )
    
  }, [props.customName, props.name])

  useImperativeHandle(ref, () => {
    return {
      handleClickOpen
    }
  }, [])

  return (
    <div>
      <div>
        {renderName}
      </div>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.isTC ? 'Terms and Conditions' : 'Booking Guarantee Policy'}
        </DialogTitle>
        <Divider className={style.divider} />
        <DialogContent>
          <Typography
            variant="body1"
            className={style.body}
            component={'p'}
          >
            {<ReactMarkdown linkTarget="_blank" source={props.message} escapeHtml={false}/>}
          </Typography>
          {props.isTC ? (
            <>
              <Typography variant="subtitle1">
                GDPR Statement from Now Book It
              </Typography>
              <Typography variant="body1">
                You may at any time, request that we amend or delete your personal information from our database,
                please follow the below link for further information on this process and how to be removed&nbsp;
                <a href="https://nowbookit.com/privacypolicy/" target="_blank" rel="noopener noreferrer">
                  https://nowbookit.com/privacypolicy/
                </a>.
              </Typography>
            </>
          ): null}
          <Typography className={style.privacyPolicyWrapper} variant="body1">
          If you use our Sites and Services, you will receive communication via electronic messages, including email, text message/SMS, or mobile push notifications in accordance with the venue's privacy policy{isPrivacyPolicyEmpty ? '.' : ''}
          {!isPrivacyPolicyEmpty && 
            <a href={props.privacyPolicyURL} target="_blank" rel="noopener noreferrer">
              {" "}{props.privacyPolicyURL}.
            </a>
          }
          </Typography>
          <Typography className={style.privacyPolicyWrapper} variant="body1">
            If you no longer want to be part of the venue's customer database or to be contacted in this manner, please unsubscribe if you receive an email or contact the venue directly to be removed.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );

}

export default forwardRef(CustomizeDialog)
