import {connect} from 'react-redux';
import DayPicker from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import moment, {Moment} from 'moment-timezone';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {IVenue, modeType} from 'app/models';
import DateUtilsService from "shared-services/date-utils-service";
import {ISchedule} from "app/services/client/client.types";
import {isEmpty} from "lodash";

const NS = 'DayPickerContainer';

// only exported for unit tests
export const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const activeVenue: IVenue = (widget.activeVenue as IVenue);
  const {theme, wrapperStyle, isTimeViewShown, booking} = widget;

  const maxDaysInFutureBooking = activeVenue
    ? activeVenue.widgetSettings.maxDaysInFutureBooking || null
    : null;

  // timezone info is not relevant for selecting the date on the date picker, since that is handled on the back end
  const today: Moment = booking.moment;
  const orgDate = booking.orgDate;

  // must use undefined instead of null to prevent error in DatePicker
  const maxDate: Moment = maxDaysInFutureBooking ? moment().add(maxDaysInFutureBooking, 'days') : undefined;

  const timeZoneId: string = activeVenue ? activeVenue.timeZoneId : null;

  if (timeZoneId && maxDate) {
    // maxDate must take into account timezone since this is not calculated on the back end
    DateUtilsService.convertMomentToVenueDateTime(maxDate, timeZoneId);
  }

  if (!timeZoneId && widget.appSettings.mode !== modeType.preview) {
    throw Error(`No 'timeZoneId' set. This is a mandatory field needed for date enabling calculations.`);
  }

  return {
    theme,
    wrapperStyle,
    today,
    viewTime: booking && booking.viewTime ? booking.viewTime : null,
    maxDate,
    isTimeViewShown: false, // NBI-951 (switched off permanently)
    orgDate,
    activeVenue
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleDayUpdate: async (moment: Moment) => {
      await dispatch(BookingActionsNS.changedBookingDate(moment));
      // making GAW api call after getSchedule call
      const allVenueSchedule: ISchedule[] = await dispatch(BookingActionsNS.getScheduleCallForAllVenues());
      if (!isEmpty(allVenueSchedule)) {
        dispatch(BookingActionsNS.getTimesForAllVenues(allVenueSchedule));
      }
    },
    handleBack: (isTimeViewShown: boolean) => {
      dispatch(BookingActionsNS.changedCalenderView(isTimeViewShown));
    }
  }
};

const DayPickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DayPicker as any);

export default DayPickerContainer;
