// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--CVPg5{padding:5px}.grid--tMpVy{justify-content:center}.listItem--6HOXl{position:relative;flex-grow:1;display:flex;flex-direction:column;height:100%}.optionsText--Gv4ll{margin-bottom:8px !important}.box--mvzL-{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--mvzL- .boxItems--5D30G{padding:8px;min-width:25%;max-width:25%}.box--mvzL-{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--mvzL- .boxItems--5D30G{padding:8px;min-width:25%;max-width:25%;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--CVPg5",
	"grid": "grid--tMpVy",
	"listItem": "listItem--6HOXl",
	"optionsText": "optionsText--Gv4ll",
	"box": "box--mvzL-",
	"boxItems": "boxItems--5D30G"
};
export default ___CSS_LOADER_EXPORT___;
