import {IWidgetModel} from "app/models";
import {IActionGen, loadStatus} from "app/types/common.types";
import {ISchedule} from "app/services/client/client.types";
import {isEmpty} from "lodash";
import {BookingService} from "app/services/booking/booking.service";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IScheduleTime} from "shared-types/index";

const NS = 'GAW';

export default {
  [BookingActionsTypes.ALL_VENUE_SCHEDULE_LOADING]: (state: IWidgetModel): IWidgetModel => {
    return {
      ...state,
      allVenueSchedule: null,
      allScheduleLoadStatus: loadStatus.loading
    }
  },

  [BookingActionsTypes.ALL_VENUE_SCHEDULE_FAILED]: (state: IWidgetModel): IWidgetModel => {
    return {
      ...state,
      allVenueSchedule: null,
      allScheduleLoadStatus: loadStatus.failed
    }
  },

  [BookingActionsTypes.ALL_VENUE_SCHEDULE_SUCCESS]: (state: IWidgetModel, action: IActionGen<ISchedule[]>): IWidgetModel => {

    let allSchedule: ISchedule[] = action.payload;

    allSchedule = !isEmpty(allSchedule) ? BookingService.validatingOtherVenues(allSchedule, state.accountDetails.ownedVenues, state.booking) : [];

    return {
      ...state,
      allVenueSchedule: allSchedule,
      allScheduleLoadStatus: loadStatus.success
    }
  },

  [BookingActionsTypes.API_CALLED]: (state: IWidgetModel, action: IActionGen<boolean>): IWidgetModel => {
    return {
      ...state,
      allVenueApiCall: action.payload
    }
  },

  [BookingActionsTypes.ALL_VENUE_TIMES]: (state: IWidgetModel, action: IActionGen<IScheduleTime[]>): IWidgetModel => {
    return {
      ...state,
      allVenueTimes: action.payload
    }
  },

  [BookingActionsTypes.SELECTED_OTHER_VENUE_TIME]: (state: IWidgetModel, action: IActionGen<IScheduleTime>): IWidgetModel => {
    return {
      ...state,
      selectedTimeForOtherVenues: action.payload
    }
  }
}
