// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--KijIF{padding:15px}.paper--foxJV{margin-left:20px;margin-right:20px;padding:15px}.btn--bjHgw{line-height:1.4}.btnWrap--rKO5\\+{padding:15px 0 0 0}.title--caE2N{padding:10px 8px 15px}.content--NYjWl{padding:8px 5px}.markdownText--BAj7I{line-height:1.3}.markdownText--BAj7I p{margin:0 0 7px 0}.markdownText--BAj7I ul,.markdownText--BAj7I ol{padding-left:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--KijIF",
	"paper": "paper--foxJV",
	"btn": "btn--bjHgw",
	"btnWrap": "btnWrap--rKO5+",
	"title": "title--caE2N",
	"content": "content--NYjWl",
	"markdownText": "markdownText--BAj7I"
};
export default ___CSS_LOADER_EXPORT___;
