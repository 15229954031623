// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--SFAOx{padding:5px 0}.rootIsStacked--RmcqT{padding:5px}.footer--hO0\\+K{display:flex;justify-content:space-between;padding-left:10px;padding-right:10px}.footerIsStacked--kWIcb{flex-direction:column}.footerIsStacked--kWIcb>*{display:flex;justify-content:center;margin-top:10px}.footerNoCarouselShown--R2s5V{margin-top:10px}.listItemsWrap--PK-Gc{width:100%;padding:5px}.grid--6iJFa{justify-content:center}.gridItemIsStacked--oY5xd{padding:4px !important}.listItem--Z5Qyv{position:relative;flex-grow:1;display:flex;flex-direction:column;height:100%}.listItemIsStacked--Qo49D{padding:4px}.listItemIsNarrow--jew6R{padding:0}.listItemCheckbox--On080{position:absolute;top:0;left:8px}.optionsText--hYS26{margin-bottom:8px !important;font-size:1.7rem !important;text-align:center !important}.dots--yAK0t{margin-top:20px;margin-bottom:5px}.dots--yAK0t .BrainhubCarousel__dot{background-color:rgba(0,0,0,0)}.dots--yAK0t .BrainhubCarousel__dot:before{background:#000 !important;width:9px !important;height:9px !important}.dotsIsDark--Gupgd .BrainhubCarousel__dot:before{background:#e0e0e0 !important}.singleList--3gOpu{padding-left:5px;padding-right:5px}.singleListIsStacked--SeCFV{padding-left:0;padding-right:0}.carouselNavBtns--bxzXZ>*{margin:0 5px}.carouselNavBtnsIsStacked--mMGvl{width:100%;display:flex;justify-content:space-between;margin-top:5px;margin-bottom:10px}.box--TUM1D{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--TUM1D .boxItems--T\\+7hD{padding:8px;min-width:25%;max-width:25%;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--SFAOx",
	"rootIsStacked": "rootIsStacked--RmcqT",
	"footer": "footer--hO0+K",
	"footerIsStacked": "footerIsStacked--kWIcb",
	"footerNoCarouselShown": "footerNoCarouselShown--R2s5V",
	"listItemsWrap": "listItemsWrap--PK-Gc",
	"grid": "grid--6iJFa",
	"gridItemIsStacked": "gridItemIsStacked--oY5xd",
	"listItem": "listItem--Z5Qyv",
	"listItemIsStacked": "listItemIsStacked--Qo49D",
	"listItemIsNarrow": "listItemIsNarrow--jew6R",
	"listItemCheckbox": "listItemCheckbox--On080",
	"optionsText": "optionsText--hYS26",
	"dots": "dots--yAK0t",
	"dotsIsDark": "dotsIsDark--Gupgd",
	"singleList": "singleList--3gOpu",
	"singleListIsStacked": "singleListIsStacked--SeCFV",
	"carouselNavBtns": "carouselNavBtns--bxzXZ",
	"carouselNavBtnsIsStacked": "carouselNavBtnsIsStacked--mMGvl",
	"box": "box--TUM1D",
	"boxItems": "boxItems--T+7hD"
};
export default ___CSS_LOADER_EXPORT___;
