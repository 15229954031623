import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import ColumnWrap2Container from '../ColumnWrap2/container';
import FooterNavContainer from '../FooterNav/container';
import SittingPanelsContainer from '../SittingPanels/container';
import DayPickerContainer from '../DayPicker/container';
import { Typography } from '@material-ui/core';

const NS = 'Sitting';

export default function Sitting({editBookingDisabled, venuePhone}: IStateFromProps & IDispatchFromProps) {

  return (
    <ColumnWrap2Container grow="right">
      {/* left */}
      <DayPickerContainer />

      {/* right */}
      <div className={style.rightColumnInner}>
        {editBookingDisabled && venuePhone ?
          <Typography>
            Sorry, you are unable to edit your booking online - please contact the venue on {venuePhone} to
            make further changes or alternatively you can cancel this booking and create a new booking
            with your new requirements.
          </Typography>
          :
          <SittingPanelsContainer />
        }
        <FooterNavContainer />
      </div>
    </ColumnWrap2Container>
  );
}
