import { wrapperStyleType, IWidgetTheme, blockNavType } from "shared-types/index";

export interface IHandlers {
  handleNavPressed: (type: footerNavTypes) => void;
  handleDisabledNextPressed?: () => void;
  handleCancelBooking: () => void;
}

export interface IProps {
  theme: IWidgetTheme;
  wrapperStyle: wrapperStyleType;
  nextEnabled: boolean;
  prevEnabled: boolean;
  nextType: footerNavTypes;
  blockNav: blockNavType;
  venuePhone: string;
  isEditMode?: boolean;
  showNextLoader: boolean;
  buttonsCentered: boolean; // group widget was true
  isStandByListAvailable: boolean;
   isDisableBookingNow?: boolean;
}


export enum footerNavTypes {
  next = 'next',
  book = 'book',
  pay = 'pay',
  preauth = 'preauth',
  prev = 'prev',
  standby = 'standby',
  manageBooking = 'manageBooking'
}
