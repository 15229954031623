// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--HpaM\\+{display:flex;justify-content:space-between}.nextBtn--5BzFZ{margin-left:8px}.navBtn--haINR{margin-left:8px}.navBtn--haINR:first-child{margin-left:0}.navBtn--haINR,.nextBtn--5BzFZ{line-height:1.3;padding:8px 15px;font-size:1.5rem}.navBtnIsChunky--5WDTy,.nextBtnIsChunky--tZZHZ{font-size:1.6rem}.disabledNext--8Hz8U{opacity:0;position:absolute;box-shadow:none;right:0}.nextWrap--ce2tV{position:relative}.navBtnIsTightFit--ex4K0,.nextBtnIsTightFit--fBACs{font-size:1.4rem;padding-right:9px;padding-left:9px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--HpaM+",
	"nextBtn": "nextBtn--5BzFZ",
	"navBtn": "navBtn--haINR",
	"navBtnIsChunky": "navBtnIsChunky--5WDTy",
	"nextBtnIsChunky": "nextBtnIsChunky--tZZHZ",
	"disabledNext": "disabledNext--8Hz8U",
	"nextWrap": "nextWrap--ce2tV",
	"navBtnIsTightFit": "navBtnIsTightFit--ex4K0",
	"nextBtnIsTightFit": "nextBtnIsTightFit--fBACs"
};
export default ___CSS_LOADER_EXPORT___;
