
import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import SimplePage from 'shared-components/simple-page/index';

const NS = 'ThankYou';


export default function ThankYou({ theme, wrapperStyle, message }: IStateFromProps & IDispatchFromProps) {

  return (
    <SimplePage theme={theme} title="Thank you" body={message} bodyIsMarkDown={true} />
  )
}

