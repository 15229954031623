// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".verificationMessage--FX9Xk{line-height:1.2;font-size:1.5rem;white-space:pre-wrap}.confirmBtnContainer--NYnhb{display:flex;justify-content:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationMessage": "verificationMessage--FX9Xk",
	"confirmBtnContainer": "confirmBtnContainer--NYnhb"
};
export default ___CSS_LOADER_EXPORT___;
