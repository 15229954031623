// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logosWrap--A2Irq{display:flex;justify-content:flex-start;height:30px;margin-top:6px}.logosWrap--A2Irq img{height:35px;margin-left:10px}.logosWrap--A2Irq img:first-child{margin-left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logosWrap": "logosWrap--A2Irq"
};
export default ___CSS_LOADER_EXPORT___;
