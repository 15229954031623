// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paperBox--hkVdp{padding:15px;display:flex;flex-direction:column;justify-content:space-between}.paperBox2--PzPe\\+{margin-bottom:15px;flex-grow:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paperBox": "paperBox--hkVdp",
	"paperBox2": "paperBox2--PzPe+"
};
export default ___CSS_LOADER_EXPORT___;
