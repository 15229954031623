import {connect} from 'react-redux';
import PaymentSummary from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps, IPaymentSummaryMenuOption} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {IStripeInfo, IEwayInfo} from 'app/services/client/client.types';
import {IVenue} from 'app/models';
import {PaymentService} from "app/services/payment/payment.service";
import {bookingStatusType, BookingType} from "app/services/booking/booking.types";
import {servicePaymentType, IServicePaymentDetails} from "shared-types/index";

const NS = 'PaymentSummaryContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const {theme, wrapperStyle, activeService, activeVenue, booking, cachedMenuOptionDetails} = widget;

  const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
  const venuePaymentSettings: IEwayInfo | IStripeInfo = activeVenue ? activeVenue.paymentSettings : null;

  const normalMenuOptions: IPaymentSummaryMenuOption[] = paymentDetails ? PaymentService.getBOForPaymentSummary(paymentDetails.options, booking.selectedMenuOptions || [], cachedMenuOptionDetails) : [];
  const upsellOptions: IPaymentSummaryMenuOption[] = paymentDetails ? PaymentService.getBOForPaymentSummary(paymentDetails.optionsUpsell, booking.selectedUpsellOptions || [], cachedMenuOptionDetails) : [];

  const menuOptions = [...normalMenuOptions, ...upsellOptions];

  const showStandbyPaymentMessage: boolean = booking.bookingType === BookingType.StandbyList
    && booking.status !== bookingStatusType.standbyPending
    && (!booking.tables || !booking.tables.length) // just a fail safe: shouldn't need to check tables if not on standbyPending status, as they should be empty otherwise when on StandbyList
    && booking.payment.paymentType !== servicePaymentType.noPayment
    && booking.payment.price > 0;

  const isPreAuth = booking.payment && booking.payment.paymentType === servicePaymentType.preAuth;

  return {
    theme,
    wrapperStyle,
    currency: activeVenue ? (activeVenue as IVenue).currency : null,
    venuePaymentProvider: venuePaymentSettings ? activeVenue.paymentSettings.paymentProvider : null,
    venuePaymentSettings,
    menuOptions,
    payment: booking.payment,
    email: booking?.bookedBy?.email ? booking?.bookedBy?.email : booking?.customer?.email,
    warningMessage: showStandbyPaymentMessage ? PaymentService.getStandbyPaidNoTableMessage(activeVenue.phone, null, 0, isPreAuth) : null
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    // handleSomeClick: () => {
    //   // dispatch(WidgetActionsNS.xxx());
    // }
  }
};

const PaymentSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSummary as any);

export default PaymentSummaryContainer;
