// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--MCOFr{margin-bottom:5px;margin-left:-6px}.timeBox--0YjmE{padding:5px 3px}.timeChip--i4eZ6{width:100%;line-height:1;height:31px}.timeNotAvailMsg--q3x29{margin:2px;text-align:center;font-size:1.2rem}.venueName---TgbM{font-weight:bold}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--MCOFr",
	"timeBox": "timeBox--0YjmE",
	"timeChip": "timeChip--i4eZ6",
	"timeNotAvailMsg": "timeNotAvailMsg--q3x29",
	"venueName": "venueName---TgbM"
};
export default ___CSS_LOADER_EXPORT___;
