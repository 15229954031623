// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--bH2Hy{padding:20px 25px 25px}.heading--8coq5{margin-bottom:15px}.divider--lAEl5{margin-bottom:15px}.dividerIsDark--svzTj{background-color:#e0e0e0}.paymentMessage--iAu7S{margin-bottom:17px}.tableWrap--TQGcq{border-bottom:1px solid rgba(0,0,0,.12);padding-top:3px}.tableWrapIsDark--XA9R3{border-bottom-color:#e0e0e0}.tableWrap--TQGcq tr td{padding:14px 6px;border-bottom:none;border-top:1px solid #e0e0e0}.cardLogos--jrVjM{margin-top:20px}.alert--ilcA7{margin-top:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--bH2Hy",
	"heading": "heading--8coq5",
	"divider": "divider--lAEl5",
	"dividerIsDark": "dividerIsDark--svzTj",
	"paymentMessage": "paymentMessage--iAu7S",
	"tableWrap": "tableWrap--TQGcq",
	"tableWrapIsDark": "tableWrapIsDark--XA9R3",
	"cardLogos": "cardLogos--jrVjM",
	"alert": "alert--ilcA7"
};
export default ___CSS_LOADER_EXPORT___;
