// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--iU19f{padding:15px}.paper--m-PAI{margin-left:20px;margin-right:20px;padding:15px}.btn--tSP4J{line-height:1.4}.btnWrap--u-POl{padding:15px 0 0 0}.title--i5oP1{padding:10px 8px 15px}.content--S8JtC{padding:8px 5px}.markdownText--JG47b{line-height:1.3}.markdownText--JG47b p{margin:0 0 7px 0}.markdownText--JG47b ul,.markdownText--JG47b ol{padding-left:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--iU19f",
	"paper": "paper--m-PAI",
	"btn": "btn--tSP4J",
	"btnWrap": "btnWrap--u-POl",
	"title": "title--i5oP1",
	"content": "content--S8JtC",
	"markdownText": "markdownText--JG47b"
};
export default ___CSS_LOADER_EXPORT___;
