import { ISelectableTime } from "app/components/TimePicker/types";
import { SECTION_ANY_ID } from "app/components/SectionSelector/types";
import { IBooking } from "../booking/booking.types";
import {ISectionOrder} from "shared-types/index";
import {cloneDeep} from "lodash";

const NS = 'TimeFilterService';

export class TimeFilterService {

  /**
   * Same as getFilteredTimes, but clones the times beforehand so originals are not modified.
   */
  static getFilteredTimesNonMutated(
    times: ISelectableTime[], omitExpired = true,
    sectionToFilterBy?: ISectionOrder, //savedBooking?: IBooking,
    utcTimeForSelectedState?: string
  ): ISelectableTime[] {
    return this.getFilteredTimesAndMutateOriginal(cloneDeep(times), omitExpired, sectionToFilterBy, utcTimeForSelectedState);
  }

  /**
   * CAREFUL! This function modifies the actual times within it, so if you are looping through sections of a service
   * using sectionToFilterBy, you may be accidentally mutating the times to isDisabled or isBlocked for the targeted
   * service. Make sure you cloneDeep the targeted service beforehand so the original service is not affected or
   * use getFilteredTimesNonMutated instead (above), but this may be less efficient when inside a sections loop.
   */
  static getFilteredTimesAndMutateOriginal(
    times: ISelectableTime[], omitExpired = true,
    sectionToFilterBy?: ISectionOrder, //savedBooking?: IBooking,
    utcTimeForSelectedState?: string
  ): ISelectableTime[] {

    let filteredTimes: ISelectableTime[] = times ? times.slice() : [];
    if (omitExpired) {
      filteredTimes = filteredTimes.filter(t => {

        const sectionId = sectionToFilterBy ? sectionToFilterBy.id : null;

        /**
         * Difference between isDisabled and isBlocked is that the former is for when a booking is unavailable due
         * to another booking taking it's place. But isBlocked is more like when an event is overlapping the time,
         * so it is never going to be available. If sectionState is false, then isBlocked should be true, as it
         * indicates the time is never available in that section.
         */
        t.isDisabled = !TimeFilterService.isTimeAvailable(t, sectionId);

        if (!sectionId || sectionId === SECTION_ANY_ID) { // if all the sections are blocked and ANY or nothing (null) has been chosen
          t.isBlocked = t.sections.every(s => s.isSectionBlocked);
        } else { // if the selected section is blocked
          t.isBlocked = t.sections.some(s => sectionId === s.id && s.isSectionBlocked);
        }

        t.isSavedTime = false; // savedBooking ? DateUtilsService.isSavedTime(t, savedBooking) : false;

        return !t.expired
      });
    }

    filteredTimes.forEach(t => {
      t.isSelected = !!(utcTimeForSelectedState && t.time === utcTimeForSelectedState);
    });

    return filteredTimes;
  }

  private static isTimeAvailable(time: ISelectableTime, sectionId: string): boolean {

    if (time.expired) {
        return false;
    }

    const sections = time.sections;
    // variable create to help debugging
    let isAvailable = false;

    if (sectionId && sectionId !== SECTION_ANY_ID) {
        isAvailable = !!sections.find(s => sectionId === s.id && s.sectionState);
        return isAvailable;
    }

    isAvailable = !!sections.find(s => s.sectionState);
    return isAvailable;
  }

  private static isSavedTime(time: ISelectableTime, booking: IBooking): boolean {

    if (!booking) {
        return false;
    }

    return booking.utcTime === time.time;
  }
}
