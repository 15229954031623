import {connect} from 'react-redux';
import ScheduleFailDialog from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {ROUTE_NAMES} from "app/services/route/route.types";
import {handleRetryLoadSchedule} from "app/actions/loadSchedule/loadScheduleActions";

const NS = 'ScheduleFailDialogContainer';


const mapStateToProps = ({widget, navigation}: IRootState): IStateFromProps => {
  return {
    currentRouteName: navigation.currentRouteName as ROUTE_NAMES,
    scheduleLoadStatus: widget.scheduleLoadStatus
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleRetry: () => {
      dispatch(handleRetryLoadSchedule());
    }
  }
};

const ScheduleFailDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleFailDialog as any);

export default ScheduleFailDialogContainer;
