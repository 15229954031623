import {connect} from 'react-redux';
import TimePicker from './index';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps, ISelectableTime, IOwnProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {MessageService} from 'app/services/message/message.service';
import FooterService from 'app/services/footer/footer.service';
import {ValidateSittingType} from 'app/services/footer/footer.types';
import {BookingService} from "app/services/booking/booking.service";
import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import {SectionsAvailableService} from "app/services/sectionsAvailable/sectionsAvailable.service";

const NS = 'TimePickerContainer';

// exported just so it can be unit tested
export const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IStateFromProps & IOwnProps => {

  const {
    triedNext, filteredTimes, booking, activeService, activeVenue, savedBooking, activeSection,
    filteredSections, theme, timeError, wrapperStyle, standbyData, standbyCleared, standbyExhausted
  } = widget;

  const enableGAW = activeVenue.groupAvailabilityActive && activeVenue.groupAvailabilitySubscriptionActive;

  const blockoutMessageEnabled = activeService && activeService.isBlockoutPartiallyEnabled;

  const isStandby = BookingService.isStandby(activeVenue.widgetSettings.standByList, booking.moment.format('dddd'), activeVenue.widgetSettings.standByListDaysWeekDays)
    && !blockoutMessageEnabled;

  const isStandbyMode = !!standbyData;

  let timeNotAvailableMessage = null;
  if (!isStandbyMode && activeVenue && activeVenue.widgetSettings && activeVenue.widgetSettings.timeNotAvailableMessage) {
    timeNotAvailableMessage = activeVenue.widgetSettings.timeNotAvailableMessage;
  }

  let alertMessage = !isStandbyMode && timeError;
  if (!isStandbyMode && !timeError && triedNext) {
    const validationResults = FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData);
    alertMessage = FooterService.getAlertMessage(validationResults, ValidateSittingType.Time);
  }

  let showStandbyMessage = !isStandbyMode && isStandby;
  const hasDisabledTimes = filteredTimes && filteredTimes.some(t => t.isDisabled && !t.isBlocked);

  if (isStandby && !isStandbyMode && !hasDisabledTimes) {
    showStandbyMessage = false;
  }

  const isSelectedServiceAvailable = SectionsAvailableService.getIsSelectedServiceAvailable(activeService, isStandbyMode, filteredSections);

  return {
    ...ownProps,
    wrapperStyle,
    theme,
    times: isStandbyMode ? filteredTimes.filter(t => {
      t.isSelected = standbyData && standbyData.utcTime && t.time === standbyData.utcTime;
      return t.isDisabled;
    }) : filteredTimes,
    emptyTimesMessage: blockoutMessageEnabled && activeService.blockoutMessage ? activeService.blockoutMessage : MessageService.get('noTablesAvailableMessage', activeVenue),
    alertMessage,
    timeNotAvailableMessage,
    showStandbyMessage,
    standbyExhausted,
    isStandbyMode,
    standbyCleared,
    isFlexibleTime: (standbyData && standbyData.isFlexibleTime) || false,
    isEditMode: !!savedBooking,
    enableGAW,
    hasActiveSection: !!activeSection,
    isSelectedServiceAvailable
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>, ownProps: IOwnProps): IDispatchFromProps => {
  return {
    handleTimeSelect: ({time}: ISelectableTime) => {
      dispatch(BookingActionsNS.changedBookingTime(time));
    },
    handleStandbyTimeSelect: (selectableTime: ISelectableTime) => {
      // if flexible time checked this will be null
      dispatch(StandbyActionsNS.selectedStandbyTime(selectableTime ? selectableTime.time : null));
    },
    handleOpenStandby: () => {
      return dispatch(StandbyActionsNS.changedStandbyMode(true));
    },
    handleCancelStandby: () => {
      if (ownProps.handleCancelStandby) {
        ownProps.handleCancelStandby();
      }
      dispatch(StandbyActionsNS.changedStandbyMode(false));
    },
    handleIsFlexibleTimeChange: (isFlexibleTime: boolean) => {
      dispatch(StandbyActionsNS.setIsFlexibleTime(isFlexibleTime));
    }
  }
};

const TimePickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimePicker as any);

export default TimePickerContainer;
