import {grey, blueGrey, lightBlue} from '@material-ui/core/colors';
import {TypographyOptions} from "@material-ui/core/styles/createTypography";
import {IWidgetPalette, IWidgetTheme, themeTypes} from "shared-types/WidgetTypes";
import {UtilsService} from "app/services/utils/utils.service";

const NS = 'AppThemeService';

export const DEFAULT_FONT = 'Roboto';

export default class AppThemeService {

  static useClassPrefix(): boolean {
    return false;
  }

  static getThemeDefaultPalette(type: themeTypes = themeTypes.light, isPaperTransparent = false): IWidgetPalette {

    const palette: any = {
      background: {
        default: '#f5f5f5'
      },
      warning: {
        main: '#f0ad4e',
        light: '#fcf8e3',
        dark: '#8a6d3b'
      },
      success: {
        main: '#c3e6cb',
        light: '#c3e6cb',
        dark: '#155724'
      },
      error: {
        main: '#f44336',
        light: '#ef9a9a',
        dark: '#b71c1c'
      }
    };

    if (type === themeTypes.outlinedDark || type === themeTypes.dark) {
      if (isPaperTransparent) {
        palette.background.paper = `rgba(${UtilsService.hexToRgb('#424242')}, 0.6)`;
      }
      return {
        ...palette,
        primary: lightBlue,
        secondary: lightBlue,
      };
    } else {
      if (isPaperTransparent) {
        palette.background.paper = `rgba(${UtilsService.hexToRgb('#fff')}, 0.7)`;
      }
      return {
        ...palette,
        primary: blueGrey,
        secondary: grey,
      };
    }
  }


  /**
   * Gets the default typography styles for text.
   * Note: 'rem' units are calculated in 10's (eg '2.5rem' = '25px'), using the `htmlFontSize` value below
   * and setting it in `src/app/containers/App/style.css` as well. This way font sizes will scale with the
   * font settings the user has on their browser.
   */
  static getThemeDefaultTypography(): TypographyOptions {
    return {
      // Tell Material-UI what's the font-size on the html element is (src/app/containers/App/style.css)
      htmlFontSize: 10,

      h1: {
        fontSize: '3rem'
      },
      h2: {
        fontSize: '2.2rem'
      },
      h3: {
        fontSize: '1.8rem'
      },
      h4: {
        fontSize: '1.6rem'
      },
      h5: {
        fontSize: '1.4rem'
      },
      h6: {
        fontSize: '1.3rem'
      },
      subtitle1: {
        fontSize: '1.6rem'
      },
      subtitle2: {
        fontSize: '1.4rem'
      },
      body1: {
        fontSize: '1.4rem'
      },
      body2: {
        fontSize: '1.3rem'
      },
      button: {
        fontSize: '1.6rem'
      },
      caption: {
        fontSize: '1.4rem'
      },
      overline: {
        fontSize: '1.4rem'
      }
    }
  }

  // just an alias for tests in shared components
  static getAppThemeAsWidgetTheme(): IWidgetTheme {
    return this.getTestTheme();
  }

  static getTestTheme(): IWidgetTheme {
    return {
      palette: this.getThemeDefaultPalette(),
      type: themeTypes.light,
      defaultColors: true,
      typography: this.getThemeDefaultTypography(),
      defaultFont: DEFAULT_FONT
    };
  }
}
