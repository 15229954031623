import React from 'react';
import style from './style.module.scss';
import {Button, CircularProgress, createStyles, makeStyles} from '@material-ui/core';
import {footerNavTypes, IHandlers, IProps} from './types';
import UtilsService from "shared-services/utils-service";
import {renderIf} from "shared-services/react-utils-service";
import {blockNavType, IWidgetTheme} from 'shared-types/index';
import classNames from 'classnames';
import {ThemeColorsService} from "shared-services/theme-colors-service";
import AlertPanel from 'shared-components/alert-panel/index';
import IframeResizerService from "shared-services/iframe-resizer-service";

const NS = 'FooterNav';

export const useFooterStyles = makeStyles((theme: IWidgetTheme) => {
  const {secondary} = ThemeColorsService.getCommonThemeStyles(theme);
  return createStyles({
    nextButton: {
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: `rgba(${ UtilsService.hexToRgb(secondary.main).join(',') }, 0.5)`,
        color: theme.palette.getContrastText(secondary.light)
      }
    },
    shadyButton: {
      '&.Mui-disabled': {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }
    }
  });
});

export default function FooterNav({
  theme, handleNavPressed, handleDisabledNextPressed, nextType, showNextLoader, buttonsCentered,isEditMode, isDisableBookingNow,
  nextEnabled, prevEnabled, wrapperStyle, blockNav, venuePhone, handleCancelBooking, isStandByListAvailable
}: IProps & IHandlers) {

  const classes = useFooterStyles(theme);
  const isStacked = IframeResizerService.isStacked(wrapperStyle);

  let nextText = 'Next';
  switch(nextType) {
    case footerNavTypes.book:
      nextText = isStandByListAvailable ? 'Add to standby list' : 'Book Now';
      break;

    case footerNavTypes.pay:
      nextText = 'Pay Now';
      break;

    case footerNavTypes.standby:
      nextText = 'Add to standby list';
      break;
  }

  const onNextButtonClicked = (navType: footerNavTypes) => {

    if (isStandByListAvailable && navType === footerNavTypes.book) {
      navType = footerNavTypes.standby;
    }

    handleNavPressed(navType);
  }

  return (
    <footer className={style.root} >

      {renderIf(blockNav === blockNavType.becauseOfPayment && venuePhone, () => (
        <div className={style.alertWrap}>
          <AlertPanel
            data-testid="paymentAlert"
            wrapperStyle={wrapperStyle}
            message={`Sorry, you are unable to add paid items to this booking online - please contact the venue on ${UtilsService.getPhoneLinkHtml(venuePhone)}
            if you require paid items to be added to your existing booking, or alternatively you can cancel this booking and create a new booking with your new requirements.`}
            buttonHandler={handleCancelBooking}
            buttonText="Cancel Booking"
          />
        </div>
      ))}
  
      <div className={classNames({
        [style.btnWrap]: true,
        [style.btnWrapIsStacked]: isStacked,
        [style.btnWrapIsCentered]: buttonsCentered
      })}>
        <div className={classNames({
          [style.isNarrow]: isStacked,
        })}>
        <Button className={classNames({
          [classes.shadyButton]: true,
          [style.navBtn]: true,
          [style.navBtnIsChunky]: isStacked
        })}
          variant="contained" disabled={!prevEnabled}
          data-testid="prevBtn"
          onClick={() => handleNavPressed(footerNavTypes.prev)}>
          Back
        </Button>
          {
            isEditMode && (
              <Button className={classNames({
                [classes.shadyButton]: true,
                [style.navBtn]: true,
                [style.navBtnIsChunky]: isStacked,
                [style.leftSpace]: !isStacked,
              })}
                      variant="contained"
                      data-testid="cancelBtn"
                      onClick={() => location.reload()}>
                Cancel Changes
              </Button>
            )
          }
          <div style={{width: 0, visibility: 'hidden'}}/>
        </div>

        <div className={style.nextWrap} >
          {renderIf(showNextLoader || !!handleDisabledNextPressed, () => (
            <Button className={classNames({
              [style.disabledNext]: true,
              [style.navBtn]: true,
              [style.navBtnIsChunky]: isStacked
            })} variant="contained" disabled={nextEnabled} color="default" onClick={handleDisabledNextPressed}>
              {nextText}
            </Button>
          ))}

          {renderIf(showNextLoader, () => (
            <CircularProgress size={20} className={style.nextLoader} />
          ))}
          <Button className={classNames({
            [classes.nextButton]: true,
            [classes.shadyButton]: true,
            [style.navBtn]: true,
            [style.navBtnIsChunky]: isStacked
          })}
            variant="contained" disabled={!nextEnabled || showNextLoader || isDisableBookingNow} color="secondary"
            data-testid="nextBtn"
            onClick={() => onNextButtonClicked(nextType)}
          >
            <span className={classNames({
              // [style.nextText]: true,
              [style.nextTextIsLoading]: showNextLoader
            })}>
              {nextText}
            </span>
          </Button>

        </div>
      </div>
    </footer>
  )
}

