import {Dispatch} from "redux";
import {IVenue} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {INabSearchResponse, ISchedule} from "app/services/client/client.types";
import {SetupActionsNS} from "app/actions/setup/setupActions";
import {IAction, IActionGen, loadStatus} from "app/types/common.types";
import {ClientService} from "app/services/client/client.service";
import {first} from "rxjs/operators";
import {appLoadCompleteSuccess} from "app/actions/setup/helpers";
import {Subscription} from "rxjs";
import { Moment } from "moment";
import {BookingService} from "app/services/booking/booking.service";
import {IRootState} from "app/reducers";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {saveBooking as _saveBooking} from "app/actions/saveBooking/saveBookingActions";
import {EditBookingActionsNS} from "app/actions/editBooking/editBookingActions";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IScheduleService, IOwnedVenue} from "shared-types/index";
import {maxBy} from 'lodash-es'

const NS = 'LoadScheduleActions';

let scheduleSubs: Subscription;
let allVenueSchedule: Subscription;

export function loadScheduleForBooking(
  dispatch: Dispatch, activeVenue: IVenue, booking: IBooking,
  useGlobalLoader: boolean, activeService: IScheduleService, isSavedBooking: boolean
): Promise<ISchedule> {

  // just stops preview mode from throwing JS errors
  if (!activeVenue) {
    return Promise.resolve(null);
  }

  return new Promise((resolve) => {
    // const maxPeoplePerBookingOverride = activeService?.paymentDetails?.maxPeoplePerBookingOverride;
    // const maxPeoplePerBooking = maxPeoplePerBookingOverride ? maxPeoplePerBookingOverride : activeVenue.widgetSettings?.maxPeoplePerBooking;

    if (booking.covers > 0 && booking.moment) {
      dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});

      dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
        completeLoadStatus: useGlobalLoader,
        status: loadStatus.loading
      }} as SetupActionsNS.IAppLoadComplete);

      dispatch({type: BookingActionsTypes.API_CALLED, payload: false}); // Reset the no availability message for GAW

      if (scheduleSubs) {
        scheduleSubs.unsubscribe();
      }
      scheduleSubs = ClientService.getSchedule(booking.moment, booking.covers, (activeVenue as IVenue).id, isSavedBooking ? booking._id : null)
        .pipe(first())
        .subscribe((value: ISchedule) => {
          let dispatchAction = BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS;
          let hasMiniumPax = false;
          // If has active service and the service has minPax. Need to get schedule for new minPax
          if(activeService){
            const services = BookingService.getAvailableServicesFromSchedule(value);
            const newActiveService: IScheduleService = value.isVenueOpen &&  services.find(s => s.name === activeService.name);
            hasMiniumPax = newActiveService && newActiveService.minPaxPerBooking ? true : false;
            if(newActiveService && newActiveService.minPaxPerBooking && newActiveService.minPaxPerBooking > booking.covers ){
                booking.covers = newActiveService.minPaxPerBooking;
                dispatchAction = BookingActionsTypes.SERVICE_SCHEDULE_LOADING;
                loadScheduleForBooking(dispatch, activeVenue as IVenue, booking, false, activeService, isSavedBooking);
            }
          }

          dispatch({type:BookingActionsTypes.HAVE_MINIMUM_PAX, payload: hasMiniumPax} as IActionGen<Boolean>);
          dispatch({type: dispatchAction, payload: value} as IActionGen<ISchedule>);
          appLoadCompleteSuccess(dispatch);
          resolve(value);
        }, err => {
          console.warn(NS, 'getSchedule', 'error', err.response);
          const payload: loadStatus = err.response?.status === 503 ? loadStatus.failedTemp : loadStatus.failed;
          dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_FAILED, payload} as IAction);
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
}

export class LoadScheduleActions {

  // exporting function within a class makes it mockable in jest tests
  static async loadNextAvailableScheduleForBooking(
    activeVenue: IVenue, paxCount: number, date: Moment, activeService: IScheduleService, isLookForward = false
  ): Promise<INabSearchResponse> {

    // just stops preview mode from throwing JS errors
    if (!activeVenue) {
      return Promise.resolve(null);
    }

    const maxPeoplePerBookingOverride = activeService?.paymentDetails?.maxPeoplePerBookingOverride;
    const maxPeoplePerBooking = maxPeoplePerBookingOverride ? maxPeoplePerBookingOverride : activeVenue.widgetSettings?.maxPeoplePerBooking;

    if (paxCount > 0 && paxCount <= maxPeoplePerBooking && date) {
      try {
        return await ClientService.getAvailableSchedules(date, paxCount, (activeVenue as IVenue).id, isLookForward).toPromise();
      } catch (error) {
        return Promise.resolve(null);
      }
    } else {
      return Promise.resolve(null);
    }
  }
}



export function getScheduleForAllVenues(
  dispatch: Dispatch, activeVenue: IVenue, booking: IBooking,
  useGlobalLoader: boolean, activeService: IScheduleService, isSavedBooking: boolean,
  forGAW?: boolean, ownedVenues?: IOwnedVenue[]
): Promise<ISchedule[]> {

  // just stops preview mode from throwing JS errors
  if (!activeVenue) {
    return Promise.resolve(null);
  }

  return new Promise((resolve) => {
    const maxPeoplePerBookingOverride = activeService?.paymentDetails?.maxPeoplePerBookingOverride;
    const maxPeopleFromAllVenus = maxBy(ownedVenues || [], (v) => v?.widgetSettings.maxPeoplePerBooking)?.widgetSettings?.maxPeoplePerBooking;
    let maxPeoplePerBooking: number;

    if (maxPeoplePerBookingOverride) {
      maxPeoplePerBooking = maxPeoplePerBookingOverride
    } else if (forGAW) {
      maxPeoplePerBooking = maxPeopleFromAllVenus
    } else {
      maxPeoplePerBooking = activeVenue.widgetSettings?.maxPeoplePerBooking;
    }

    if (booking.covers > 0 && booking.covers <= maxPeoplePerBooking && booking.moment) {
      dispatch({type: BookingActionsTypes.ALL_VENUE_SCHEDULE_LOADING});
      if (allVenueSchedule) {
        allVenueSchedule.unsubscribe();
      }
      allVenueSchedule = ClientService.getAllSchedule(booking.moment, booking.covers, (activeVenue as IVenue).id, isSavedBooking ? booking._id : null)
        .pipe(first())
        .subscribe((value: ISchedule[]) => {

          dispatch({
            type: BookingActionsTypes.ALL_VENUE_SCHEDULE_SUCCESS,
            payload: value
          } as IActionGen<ISchedule[]>);
          dispatch({
            type: BookingActionsTypes.API_CALLED,
            payload: true
          } as IActionGen<boolean>);
          resolve(value);
        }, err => {
          console.warn(NS, 'getSchedule', 'error', err);
          dispatch({type: BookingActionsTypes.ALL_VENUE_SCHEDULE_FAILED} as IAction);
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
}


export const handleRetryLoadSchedule = () => (dispatch: Dispatch, getState: () => any): Promise<any> => {
  const {widget, navigation} = getState() as IRootState;

  if (navigation.currentRouteName === ROUTE_NAMES.SUMMARY) {
    dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});
    return _saveBooking(dispatch, getState)
  }

  if (navigation.currentRouteName === ROUTE_NAMES.SETUP) {
    dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_LOADING});
    return EditBookingActionsNS._editBooking(dispatch, widget)
  }

  // called when on the sitting page
  return loadScheduleForBooking(dispatch, widget.activeVenue as IVenue, widget.booking, false, widget.activeService, !!widget.savedBooking);
}
