// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box--Y92IT{display:flex;justify-content:center;flex-direction:row;flex-wrap:wrap}.box--Y92IT .boxItems--Ev7mw{padding:8px;min-width:25%;max-width:25%;display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "box--Y92IT",
	"boxItems": "boxItems--Ev7mw"
};
export default ___CSS_LOADER_EXPORT___;
