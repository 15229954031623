import {App} from ".";
import { IRootState } from 'app/reducers';
import { connect } from "react-redux";
import {hot} from "react-hot-loader";
import { AnyAction } from "redux";
import { SetupActionsNS } from "app/actions/setup/setupActions";
import { ThunkDispatch } from "redux-thunk";
import { modeType, wrapperStyleType, IAppSettings, IVenue } from "app/models";
import { WidgetActionsNS } from "app/actions/widget";
import { ThemeColorsService } from "app/services/theme/themeColors.service";
import { IPreviewUpdate, themeMessageTypes } from "app/services/theme/theme.types";
import { IframeResizerService } from "app/services/iframeResizer/iframeResizer.service";
import { IStateFromProps, IDispatchFromProps } from "./types";
import { RouteService } from "app/services/route/route.service";
import { BookingActionsNS } from "app/actions/booking/bookingActions";
import { NavigationActionsNS } from "app/actions/navigation/navigationActions";
import { ROUTE_NAMES, IRouteState } from "app/services/route/route.types";
import { StateDeclaration } from "@uirouter/react";
import { AnalyticsService } from "app/services/analytics/analytics.service";
import { LocationService } from "app/services/location/location.service";
import RouteStates from "app/services/route/route.states";
import {servicePaymentType} from "shared-types/index";

export interface IAppProps {
  startLoading: () => void;
}

const NS = 'AppContainer';

const mapStateToProps = ({widget, setup}: IRootState): IStateFromProps => {
  const {wrapperStyle, appSettings, theme, stripe} = widget;
  const {appLoadStatus, appLoaderType} = setup;
  const isStepRoute = RouteService.isCurrentRouteInSteps();

  return {
    wrapperStyle,
    theme,
    appLoadStatus,
    appLoaderType,
    appSettings,
    isStepRoute,
    stripe
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    startLoading: () => {

      dispatch(NavigationActionsNS.changedRouteByName(ROUTE_NAMES.SETUP));

      let appLoaded = false;
      let wrapperStyle = wrapperStyleType.standard;

      const handleResizeChange = () => {
        if (appLoaded) {
          dispatch({type: WidgetActionsNS.Type.CHANGED_SIZE, payload: wrapperStyle});
        }
      };

      /**
       * must get called immediately as `window.iFrameResizer` object needs to exist when
       * `iframeResizer.contentWindow.min.js` is loaded.
       */
      IframeResizerService.handleSizeChange()
        // long running subscription
        .subscribe((_wrapperStyle: wrapperStyleType) => {
          wrapperStyle = _wrapperStyle;
          handleResizeChange();
        });

      (dispatch(SetupActionsNS.appLoaded()) as Promise<SetupActionsNS.IAppLoadedResolved>)
        .then(({appSettings, booking, activeVenue}: SetupActionsNS.IAppLoadedResolved) => {

          // @todo experiment with page transitions. severity: low
          RouteService.registerTransitions(
            {
              transitionStart: (from: StateDeclaration, to: StateDeclaration, id: number) => {
                // console.log('started from', from);
                // console.log('started to', to);
                dispatch( NavigationActionsNS.transitionStarted(from.name as ROUTE_NAMES, to.name as ROUTE_NAMES, id));
              },
              transitionEnd: (from: StateDeclaration, to: StateDeclaration, id: number) => {
                // console.log('finsihed from', from);
                // console.log('finsihed to', to);
                dispatch( NavigationActionsNS.transitionFinished(from.name as ROUTE_NAMES, to.name as ROUTE_NAMES, id));
              },
              transitionFail: (from: StateDeclaration, to: StateDeclaration, id: number) => {

              }
            });

          // if in preview mode, waits for color updates from parent window
          if (appSettings.mode === modeType.preview) {
            ThemeColorsService.listenForPreviewUpdates() // this is a long running watch
              .subscribe((data: IPreviewUpdate) => {
                if (data) {
                  if (data.type === themeMessageTypes.colors) {
                    dispatch( WidgetActionsNS.changedThemeColors(data.value) );
                  } else if(data.type === themeMessageTypes.theme) {
                    LocationService.setBgColor(data.value);
                    dispatch( WidgetActionsNS.changedThemeStyle(data.value) );
                  } else if(data.type === themeMessageTypes.font) {
                    dispatch( WidgetActionsNS.changedFont(data.value) );
                  }
                }
              });
            appLoaded = true;
            handleResizeChange();
            return;
          }

          /**
           * First page gets sent to google analytics, unless it is the `venues` page because the google
           * analytics key exists at the venue level, which has not yet been chosen
           */
          const {analyticsUrl, analyticsTitle} = RouteStates.getCurrentRoute();
          if (activeVenue) {
            AnalyticsService.pageView(analyticsUrl, analyticsTitle, activeVenue.name);
          }

          const hasPayment = !!booking.payment;

          // if date and covers is prefilled by query strings, then we need to load the schedule
          if (booking.moment && booking.covers && (!hasPayment || booking.payment.paymentType !== servicePaymentType.functionPayment)) {
            dispatch(BookingActionsNS.readyToLoadSchedule(true))
              .then(() => {
                if (booking.serviceId) {
                  dispatch(BookingActionsNS.changedActiveService(booking.serviceId))
                }
                if (booking.isGaw) {
                  dispatch(BookingActionsNS.changeServiceBasedOnGAW()); // if gaw is selected means time is coming from another venue. Select service and time
                }
                appLoaded = true;
                handleResizeChange();
              })
              .catch(err => {
                console.warn(NS, 'error trying to load schedule', err);
                dispatch( SetupActionsNS.appLoadFailed(err) );
              });
          } else {
            appLoaded = true;
            handleResizeChange();
          }
        }).catch(err => {
          console.warn(NS, 'appLoaded err', err);

          appLoaded = true;
          handleResizeChange();
          dispatch(SetupActionsNS.appLoadFailed(err));
        });
    }
  }
}

const AppContainer = connect(
  mapStateToProps, mapDispatchToProps
)(App as any);

// export default AppContainer;
export default hot(module)(AppContainer)

