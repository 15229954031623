import React, { ChangeEvent } from 'react';
import { IPaymentAgree } from './types';
import style from './style.module.scss';
import { Checkbox, FormControl, FormHelperText, withStyles } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { renderIf } from 'app/services/utils/utils.service';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import AlertPanel from '../AlertPanel';
import {servicePaymentType} from "shared-types/index";

const NS = 'PaymentAgree';


const FormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      fontSize: '1.2rem'
    }
  }
})(MuiFormControlLabel);


export default function PaymentAgree({ handleChange, paymentType, showError, wrapperStyle}: IPaymentAgree) {

  const label = `I hereby give authorisation
    to ${paymentType === servicePaymentType.preAuth ? 'verify' : 'deduct'} the
    amount specified above. I agree that I am the authorised card holder of the credit card listed.`;

  return (
    <div className={style.root}>
      <FormControl required component="fieldset">
        <FormControlLabel
          className={style.labelText}
          onChange={(event: ChangeEvent<{}>, checked: boolean) => {
            handleChange(checked);
          }}
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
            />
          }
          label={label}
        />
        {renderIf(showError, () => (
          <div className={style.alert}>
            <AlertPanel wrapperStyle={wrapperStyle} message="To proceed you must agree to the terms and conditions." />
          </div>
        ))}
      </FormControl>
    </div>
  )
}
