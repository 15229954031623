import { connect } from 'react-redux';
import ManageBooking from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { IVenue } from 'app/models';
import { BookingActionsNS } from 'app/actions/booking/bookingActions';
import { ClientService } from 'app/services/client/client.service';
import { IBookingMenuOption } from 'app/services/booking/booking.types';
import { IPaymentSummaryMenuOption } from 'app/components/PaymentSummary/types.d';
import { SetupActionsNS } from 'app/actions/setup/setupActions';
import {EditBookingActionsNS} from "app/actions/editBooking/editBookingActions";
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import {canEditBooking} from 'app/components/ManageBooking/helpers';
import {BookingService} from 'app/services/booking/booking.service';

const NS = 'ManageBookingContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {theme, wrapperStyle, booking, activeVenue, appSettings, manageBookingErrorMessage, savedBooking, cachedMenuOptionDetails} = widget;
  const {customer, bookedBy, isBookedBy} = booking;

  const bookingTime = new Date(booking.rhinoTime);
  const venueTime = BookingService.getVenueTime(activeVenue as IVenue);

  const canEdit = canEditBooking(booking, appSettings.source, activeVenue as IVenue, bookingTime, venueTime);

  let viewDate = null, covers = 0, viewTime = null;
  let selectedMenuOptions: IBookingMenuOption[] = [];

  if (!canEdit && booking) {
    covers = booking.covers;
  }

  if (savedBooking) {
    viewDate = savedBooking.viewDate;
    covers = savedBooking.covers;
    viewTime = savedBooking.viewTime;
    selectedMenuOptions = savedBooking.selectedMenuOptions;
  }

  let firstName, lastName, company, phone, email, postcode, membershipNumber, birthdayDate, birthdayMonth, birthdayYear;
  if (customer) {
    firstName = customer.firstName || '';
    lastName = customer.lastName || '';
    company = customer.company || '';
    postcode = customer.postcode || '';
    membershipNumber = customer.membershipNumber || '';
    birthdayDate = customer.birthdayDate;
    birthdayMonth = customer.birthdayMonth;
    birthdayYear = customer.birthdayYear;
    phone = customer.phone || '';
    email = customer.email || '';
  }

  const birthday = birthdayDate && birthdayMonth && birthdayYear
                    ? new Date(birthdayYear, birthdayMonth - 1, birthdayDate).toLocaleDateString()
                    : '';

  const getChildLineItemDetails = (opts: IBookingMenuOption[]): IPaymentSummaryMenuOption[] => {
    if (!opts) {
      return [];
    }
    return opts.reduce((acc, {menuOptionId, quantity}) => {
      const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
      if (details) {
        acc.push({ label: details.label, price: details.price, quantity });
      }
      return acc;
    }, []);
  };


  return {
    theme,
    wrapperStyle,
    booking,
    appSettings,
    activeVenue: activeVenue as IVenue,
    tableData: {
      items: [
        { name: 'Venue Name', value: activeVenue.name },
        { name: 'Booking Date', value: viewDate },
        { name: 'For', value: covers.toString() },
        { name: 'At', value: viewTime },
        { name: 'First Name', value: firstName },
        { name: 'Surname', value: lastName },
        { name: 'Company Name', value: company },
        { name: 'Mobile', value: phone },
        { name: 'Email', value: email },
        { name: 'Birthday', value: birthday},
        { name: 'Postcode', value: postcode },
        { name: 'Membership Number', value: membershipNumber },
      ]
    },
    manageBookingErrorMessage,
    menuOptions: selectedMenuOptions.reduce((acc, {menuOptionId, quantity, extras}) => {

      const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
      let childLineItems = extras && getChildLineItemDetails(extras.implicitChildMenuOptions);

      if (extras && extras.explicitChildMenuOptions) {
        extras.explicitChildMenuOptions.forEach(opts => {
          childLineItems = childLineItems.concat(getChildLineItemDetails(opts));
        });
      }

      if (details) {
        acc.push({ label: details.label, price: details.price, quantity, childLineItems });
      }
      return acc;
    }, []),
    standbyConfirmationTimeoutInMinutes: (activeVenue as IVenue).widgetSettings.standbyConfirmationTimeoutInMinutes,
    isBookedBy,
    bookedBy,
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleCancelBooking: () => {
      return dispatch(BookingActionsNS.cancelBooking());
    },
    handleEditBooking: () => {
      dispatch(EditBookingActionsNS.editBooking());
    },
    handleConfirmBooking: () => {
      return dispatch(BookingActionsNS.confirmBooking());
    },
    handleGoToPayment: () => {
      dispatch(SetupActionsNS.openPayments());
    },
    handleRebook: () => {
      dispatch(NavigationActionsNS.reload(true));
    }
  }
};

const ManageBookingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageBooking as any);

export default ManageBookingContainer;
