// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paper--D2IBN{margin-left:20px;margin-right:20px;padding:15px}.btn--j0M-n{line-height:1.4}.btnWrap--TwZ3P{padding:15px 0 0 0}.title--zY1kF{padding:10px 8px 15px}.content--flxcv{padding:8px 5px}.markdownText--ogw6q{line-height:1.3}.markdownText--ogw6q p{margin:0 0 7px 0}.markdownText--ogw6q ul,.markdownText--ogw6q ol{padding-left:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "paper--D2IBN",
	"btn": "btn--j0M-n",
	"btnWrap": "btnWrap--TwZ3P",
	"title": "title--zY1kF",
	"content": "content--flxcv",
	"markdownText": "markdownText--ogw6q"
};
export default ___CSS_LOADER_EXPORT___;
