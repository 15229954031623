// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--b0-TO{padding:3px 12px}.link--Skfnj a{color:#fff}.btnContainer--FCt-A{display:flex;flex-direction:row}.space--OHMGH{justify-content:space-around}.btn--IljVY{max-width:140px;margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--b0-TO",
	"link": "link--Skfnj",
	"btnContainer": "btnContainer--FCt-A",
	"space": "space--OHMGH",
	"btn": "btn--IljVY"
};
export default ___CSS_LOADER_EXPORT___;
