import React, {useEffect, useRef, useState, useMemo, ChangeEvent} from 'react';
import style from './style.module.scss';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import ReactFlagsSelect from 'react-flags-select';
import { CountriesWithCode } from 'external-services/phone-number-service/country';
import PhoneIcon from '@material-ui/icons/Phone';
import MuiTextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { ICustomer } from 'shared-types/index';
import UtilsService from 'external-services/utils-service/index';
import PhoneNumberService from 'external-services/phone-number-service/index';
import { phoneNumberTypes } from 'external-services/phone-number-service/phoneNumber.types';
import { FlagPhonePickerStyle, IHandlers, IProps } from './types';
import { themeTypes } from 'shared-types/index';

const NS = 'FlagPhonePickerReact';

export type Countries = Record<string, string>;

// export default function FlagPhonePickerReact(params: IProps & IHandlers) {
export default function FlagPhonePickerReact(props: IProps & IHandlers) {
  const {
    isPhoneIcon,
    value,
    handleBlur,
    handleChange,
    country,
    searchCustomer,
    customers,
    handleWholeFormChangeForAutocomplete,
    forceValidation,
    touched,
    errors,
    label,
    uiStyle,
    theme,
    maxLength,
    classPrefix,
    usePlaceholderForLabel,
    isDropDownListMoveUp,
    disabled,
    overrideStyles,
    name = 'phone',
    required
  } = props;
  const [selectedCountry, setSelectedCountry] = useState(country ? country : '');
  const [nationalNumber, setNationalNumber] = useState('');

  const [numberMaxLength, setNumberMaxLength] = useState(25);

  const dropDownRef = useRef(null);

  const isDark = theme
    ? theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark
    : false;

    // defaults to true for all non-diary instances
let isRequired = uiStyle !== FlagPhonePickerStyle.diary; 

// unless 'required' is specified, in which case it overrides the default
if (required !== undefined) {
  isRequired = required;
}

  useEffect(() => {
    country !== selectedCountry && setSelectedCountry(country);
  }, [country]);

  useEffect(() => {
    if (maxLength && uiStyle === FlagPhonePickerStyle.diary) {
      const countryData = PhoneNumberService.getCountryByCountryCode(selectedCountry);
      if (nationalNumber && PhoneNumberService.isNotPhoneNumberType(nationalNumber)) {
        setNumberMaxLength(maxLength);
      } else {
        if (countryData) {
          setNumberMaxLength(maxLength - countryData.dial_code.length);
        } else {
          setNumberMaxLength(maxLength);
        }
      }
    }
  }, [selectedCountry, nationalNumber]);

  const TextField = useMemo(
    () =>
      withStyles({
        root: {
          '& > .MuiInputLabel-shrink': {
            transform: 'translate(0, 5px) scale(0.75)',
          },
          '& .MuiInputBase-input': {
            padding: '5px 0 6px',
            marginTop: uiStyle === FlagPhonePickerStyle.widget ? '5px' : '4px',
          },
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiInputBase-root`]:
            uiStyle === FlagPhonePickerStyle.diary
              ? {
                  fontSize: '16px',
                }
              : {},
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiFormLabel-root`]:
            uiStyle === FlagPhonePickerStyle.diary
              ? {
                  color: 'rgba(0, 0, 0, 0.26)',
                }
              : null,
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiInputLabel-shrink`]:
            uiStyle === FlagPhonePickerStyle.diary
              ? {
                  transform: 'translate(0, 5px) scale(0.75)',
                  color: 'rgba(0, 0, 0, 0.6)',
                }
              : null,
          '& .MuiFormHelperText-root': {},
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiFormHelperText-root`]: {
            fontFamily: 'Roboto, Helvetica Neue, sans-serif',
            letterSpacing: '0',
          },
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiInput-underline.Mui-error:after`]:
            uiStyle === FlagPhonePickerStyle.diary
              ? {
                  borderBottomColor: 'rgba(0, 0, 0, 0.03)',
                }
              : null,
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiFormHelperText-root.Mui-error`]:
            uiStyle === FlagPhonePickerStyle.widget
              ? {
                  fontSize: '12px',
                }
              : null,
          [`& .${classPrefix ? classPrefix + '-' : ''}MuiFormLabel-root.Mui-focused`]:
            uiStyle === FlagPhonePickerStyle.diary
              ? usePlaceholderForLabel
                ? {
                    color: 'rgba(0, 0, 0, 0.26)',
                  }
                : {
                    color: overrideStyles?.focusedColor,
                  }
              : null,
          '& label': uiStyle !== FlagPhonePickerStyle.widget ? {
            zIndex: 10,
            fontSize: '1.6rem',
          } : null,
          '& label + .MuiInput-formControl': {
            marginTop: uiStyle === FlagPhonePickerStyle.widget ? '13px' : '12px',
          },
        },
      })(MuiTextField),
    []
  );
  useEffect(() => {
    window.addEventListener('click', (e: any) => {
      if (e?.target?.id === 'rfs-btn') {
        const ulChild = dropDownRef.current?.children[0].children[1];
        if (ulChild) {
          const inputChild = ulChild.children[0].children[0];
          inputChild.focus();
        }
      }
    });
  }, []);

  const countries = CountriesWithCode.map((c) => c.code);
  const countryLabels: Countries = {};
  CountriesWithCode.forEach((c) => {
    countryLabels[c.code] = c.code + ' ' + c.dial_code;
  });

  const formatPhoneNumber = (phone: string, countryCode: string) => {
    // should be return country-dial-code-removed-number
    let label = countryLabels[countryCode]; // get country label ex: "AF +93"
    label = label.replace(countryCode + ' ', ''); // Remove country code from label ex: "+93"
    label = label + ' '; // Add space into label ex: "+93 "
    return phone ? phone.replace(label, '') : ''; // Remove label from phone number ex: "+61 2343 2343 2343" => "2343 2343 2343"
  };

  const formatAutoCompletePhoneNumber = (value: string) => {
    const parseNumber: any = PhoneNumberService.parseNumber(value, true);
    if (parseNumber && typeof parseNumber !== 'string') {
      if (parseNumber.nationalNumber) {
        setTimeout(() => {
          // setSelectedCountry(parseNumber.country);
          handleChange({
            ...{},
            target: {
              name: name,
              value: parseNumber.nationalNumber,
            },
          });
          country !== parseNumber.country &&
            handleChange({
              ...{},
              target: {
                name: 'country',
                value: parseNumber.country,
              },
            });
        }, 100);
        return parseNumber.nationalNumber;
      } else return value;
    }
    return value;
  };

  const checkCountryCode = (str: string): string => {
    if (str === '+1') {
      setSelectedCountry('US');
      handleChange({
        ...{},
        target: {
          name: 'country',
          value: 'US',
        },
      });
      return '';
    }
    const isExistedCode: {
      name: string;
      dial_code: string;
      code: string;
    } = PhoneNumberService.getCountryByDialCode(str);
    if (isExistedCode) {
      setSelectedCountry(isExistedCode.code);
      handleChange({
        ...{},
        target: {
          name: 'country',
          value: isExistedCode.code,
        },
      });
      return '';
    } else {
      return str;
    }
  };

  return (
    <div
      className={classNames({
        [style.root]: true,
      })}
    >
      {isPhoneIcon && (
        <div>
          <PhoneIcon />
        </div>
      )}
      <div
        ref={dropDownRef}
        className={classNames({
          [style.prefixContainer]: true,
          [style.prefixPaddingContainer]:
            uiStyle !== FlagPhonePickerStyle.diary &&
            ((forceValidation && !value) || ((touched || value) && Boolean(errors))),
          [style.wrapperContainer]:
            uiStyle === FlagPhonePickerStyle.diary && Boolean(errors),
          [style.darkMode]: isDark,
        })}
      >
        <ReactFlagsSelect
          searchable
          disabled={disabled}
          fullWidth
          placeholder={'Country Code'}
          countries={countries}
          customLabels={countryLabels}
          selected={selectedCountry}
          onSelect={(code) => {
            console.log(code);
            setSelectedCountry(code);

            handleChange({
              ...{},
              target: {
                name: 'country',
                value: code,
              },
            });
          }}
          className={classNames({
            [style.prefixFlagContainer]: true,
            [style.dropDownListUp]: isDropDownListMoveUp,
            [style.prefixDiaryPaddingContainer]: uiStyle === FlagPhonePickerStyle.diary && Boolean(errors) && !usePlaceholderForLabel,
            [style.whiteBackground]: disabled,
          })}
          selectButtonClassName={classNames({
            [style.prefixButtonContainer]: true,
            [style.isDiaryPrefixButtonContainer]: uiStyle === FlagPhonePickerStyle.diary,
          })}
          selectedSize={12}
          optionsSize={12}
        />
      </div>
      <div
        className={classNames({
          [style.phoneNumberInputContainer]: true,
          [style.isDiary]: uiStyle === FlagPhonePickerStyle.diary,
        })}
      >
        <Autocomplete
          freeSolo
          disabled={disabled}
          disableClearable
          inputValue={value ? formatAutoCompletePhoneNumber(value) : ''}
          onChange={(event: ChangeEvent<{}>, newValue: string | ICustomer) => {
            handleWholeFormChangeForAutocomplete(newValue as ICustomer);
          }}
          renderOption={(option: ICustomer) => (
            <React.Fragment>
              <span>{UtilsService.getDisplayName(option, true)}</span>
            </React.Fragment>
          )}
          getOptionLabel={(option) => option.phone}
          options={customers as ICustomer[]}
          renderInput={(params) => (
            <TextField
              data-testid="data-test-id-phone-flag-picker-value"
              {...params}
              label={
                usePlaceholderForLabel
                  ? null
                  : label ??
                    (uiStyle !== FlagPhonePickerStyle.diary ? <span>Mobile</span> : 'Phone')
              }
              inputProps={
                uiStyle === FlagPhonePickerStyle.diary && maxLength
                  ? {
                      ...params.inputProps,
                      maxLength: numberMaxLength,
                    }
                  : {
                      ...params.inputProps,
                    }
              }
              placeholder={usePlaceholderForLabel ? 'Phone' : ''}
              name={name}
              required={isRequired}
              fullWidth
              value={nationalNumber}
              onChange={(evt) => {
                let str: string = evt.target.value;
                str = checkCountryCode(str.replace(/[^+0-9]/g, ''));
                const international: string = selectedCountry
                  ? formatPhoneNumber(
                      PhoneNumberService.formatNumber(
                        str,
                        selectedCountry,
                        true,
                        phoneNumberTypes.INTERNATIONAL
                      ),
                      selectedCountry
                    )
                  : '';

                setNationalNumber(selectedCountry && international ? international : str);
                const event = {
                  ...evt,
                  target: {
                    name,
                    // in theory, the country should always exist, but if it doesn't for some reason, we let them skip formating
                    value: selectedCountry && international ? international : str,
                  },
                };
                handleChange(event);
                const codeData = PhoneNumberService.getCountryByCountryCode(selectedCountry);
                searchCustomer(event, codeData?.dial_code);
              }}
              onBlur={handleBlur}
              error={
                uiStyle !== FlagPhonePickerStyle.diary
                  ? (forceValidation && !value) || ((touched || !!value) && Boolean(errors))
                  : Boolean(errors)
              }
              helperText={
                uiStyle !== FlagPhonePickerStyle.diary
                  ? touched || value
                    ? errors
                    : ''
                  : touched
                  ? errors
                  : ''
              }
            />
          )}
          /**
           * should be return all options without own filter inside autocomplete
           */
          filterOptions={(options, state) => {
            return options;
          }}
        />
      </div>
    </div>
  );
}
