// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cvcPopover--5KoyM{padding:10px 15px;width:305px;background-color:#fff}.cvcPopoverImg--PCKLZ{width:275px;height:275px}.cvcPopoverText--bwyY5{font-size:1.1rem}.cvcPopoverTextIsDark--q88RF{color:rgba(0,0,0,.87)}.cvcLink--Kdup0{position:absolute;right:0;bottom:6px;font-size:1rem;padding:3px 9px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cvcPopover": "cvcPopover--5KoyM",
	"cvcPopoverImg": "cvcPopoverImg--PCKLZ",
	"cvcPopoverText": "cvcPopoverText--bwyY5",
	"cvcPopoverTextIsDark": "cvcPopoverTextIsDark--q88RF",
	"cvcLink": "cvcLink--Kdup0"
};
export default ___CSS_LOADER_EXPORT___;
