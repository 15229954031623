import { IDispatchFromProps, IStateFromProps } from 'app/components/Payments/types';
import Payments from './index';
import NarrowPayments from './narrow';
import { IframeResizerService } from 'app/services/iframeResizer/iframeResizer.service';
import React from 'react';
export default function Switch(props: IStateFromProps & IDispatchFromProps) {

  if (IframeResizerService.isStacked(props.wrapperStyle)) {
    return (<NarrowPayments {...props}/>)
  } else {
    return (<Payments {...props}/>)
  }
}
