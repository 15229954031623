import {IActionGen} from "app/types/common.types";
import {ICoversUpdate} from "app/actions/booking/interfaces";
import {IWidgetModel} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";

const NS = 'changedCoversCount';

export default {
  [BookingActionsTypes.CHANGED_COVERS_COUNT]: (state: IWidgetModel, action: IActionGen<ICoversUpdate>): IWidgetModel => {
    state = {
      ...state,
      coversChanged: state.booking.covers !== action.payload.covers
    };
    const booking: IBooking = {
      ...state.booking,
      covers: action.payload.covers
    };

    if (action.payload.clearSelectionMenuOptions) {
      // need to reset these because they may contain counters that depend on covers value
      booking.selectedMenuOptions = [];
    }

    return {
      ...state,
      booking,
      timeError: null,
      triedNext: null
    }
  }

}
