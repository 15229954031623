import React, {useEffect} from 'react';
import style from './style.module.scss';
import {IGAWTimeList} from './types';
import {renderIf} from 'app/services/utils/utils.service';
import {
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import {loadStatus} from "app/types/common.types";
import {isEmpty} from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import {SimplePaletteColorOptions} from "@material-ui/core/styles/createPalette";
import GroupAvailabilityWidgetContainer from "app/components/GroupAvailabilityWidget/container";
import classNames from "classnames";
import {BookingService} from "app/services/booking/booking.service";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 'calc(100vh - 40px)',
      minWidth: 300, // extra width to allow for scroll bars
      maxWidth: 400,
      paddingRight: 10
    }
  },
  MenuListProps: {
    style: {
      paddingTop: 2,
      paddingBottom: 2
    }
  }
};

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginTop: theme.spacing(2),
    lineHeight: 1,
    display: 'block',
    color: theme.palette.getContrastText((theme.palette.secondary as SimplePaletteColorOptions).main),
  },
  noAvailability: {
    textAlign: "center",
    fontStyle: "italic"
  },
  contrastColor: {
    color: "white",
  },
  gawHeading: {
    padding: '15px',
    margin: '0 0 10px 0',
    backgroundColor: "black",
    color: "white",
    borderRadius: '3px'
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },

}));

/** Displays the available times in a chip */
export const GAWTimeList = ({allScheduleLoadStatus, allVenueSchedule, allVenueTimes, handleVenueTimeChange, activeService, selectedTimeForOtherVenues}: IGAWTimeList) => {

  const [venueTime, setVenueTime] = React.useState<string>('');
  const [isTimesAvailable, setIsTimesAvailable] = React.useState<boolean>(false);

  const classes = useStyles();

  const changeVenueTime = (event: any) => {
    const time = event.target.value as string;
    setVenueTime(time)
    handleVenueTimeChange(allVenueTimes.find(t => t.time === time));
  }

  useEffect(() => {
    if (!isEmpty(allVenueTimes)) {
      // by default we take the current service start time to show the start time of the GAW times - in the event where the venue is closed and there are no times - we take the 1st available time from the other venue time list
      const selectedTime = activeService && !isEmpty(activeService.times) ? activeService.times[0] : allVenueTimes[0];
      setVenueTime(selectedTime.time);
      handleVenueTimeChange(selectedTime);
    }
  }, [allVenueTimes, activeService]);

  useEffect(() => {
    if (!isEmpty(selectedTimeForOtherVenues) && !isEmpty(allVenueTimes)) {
      const isTimesAvailable = allScheduleLoadStatus === loadStatus.success && selectedTimeForOtherVenues && allVenueSchedule.some((schedule) => {
        const filteredTimes = BookingService.getFilteredTimesForVenue(schedule.services, selectedTimeForOtherVenues);
        return filteredTimes.length > 0
      })
      setIsTimesAvailable(isTimesAvailable)
    } else {
      setIsTimesAvailable(false)
    }
  }, [selectedTimeForOtherVenues, allVenueTimes])

  return (
    <>
      {renderIf(isTimesAvailable, () => (
          <Paper className={style.standbyMsg} elevation={0}>
            <div className={classes.gawHeading}>
              <Typography variant="h5" component="div">Select time picker to view alternative times</Typography>

              <Grid container>
                <Grid item xs={5}>
                  <Select
                    data-testid="gaw-times"
                    displayEmpty
                    className={classNames({
                      [style.venueTimesSelect]: true,
                      [classes.contrastColor]: true,
                      [classes.select]: true
                    })}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                        root: classes.root,
                      },
                    }}
                    labelId="venue-times-label"
                    id="venue-times"
                    value={venueTime}
                    input={<Input/>}
                    onChange={changeVenueTime}
                    MenuProps={MenuProps}>
                    {allVenueTimes.map((time, index) => (
                      <MenuItem
                        key={index}
                        value={time.time}
                        data-e2e-gaw-dropdown-time={time.name}
                      >{time.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
            {renderIf(!isEmpty(allVenueSchedule), () => (
              <div data-e2e="gaw-time-selector">
                <GroupAvailabilityWidgetContainer/>
              </div>
            ))}
          </Paper>
        ), () => (
          <>
            <Paper className={style.standbyMsg} elevation={0}>
              {renderIf(allScheduleLoadStatus === loadStatus.loading, () => (
                <CircularProgress size={20} className={style.nextLoader}/>
              ))}
            </Paper>
          </>
        )
      )}
    </>
  )
}
