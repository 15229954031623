// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paper--PXLaz{margin-left:20px;margin-right:20px;padding:15px;width:310px;min-height:250px}.btn--58Dbc{line-height:1.4}.btnWrap--APSRz{padding:15px 0 0 0}.title--YEQit{padding:10px 8px 15px}.content--1GwWN{padding:8px 5px;display:flex;justify-content:center;align-items:center}.content--1GwWN p{font-size:1.6rem;margin-bottom:1.5rem}.bodyText--8A9kf{position:static}.closeButton--FSxIb{position:absolute;right:1px;top:1px;color:gray}.loaderWrap--HpUAh{width:100%;display:flex;flex-direction:column;align-items:center}.loaderWrap--HpUAh p{margin-top:15px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "paper--PXLaz",
	"btn": "btn--58Dbc",
	"btnWrap": "btnWrap--APSRz",
	"title": "title--YEQit",
	"content": "content--1GwWN",
	"bodyText": "bodyText--8A9kf",
	"closeButton": "closeButton--FSxIb",
	"loaderWrap": "loaderWrap--HpUAh"
};
export default ___CSS_LOADER_EXPORT___;
