// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loaderPanel--AOOjt{min-height:100px;width:100%;display:flex;justify-content:center;align-items:center;margin-bottom:12px}.paper--18dhr{padding:20px 23px;margin-bottom:15px}.selectCreditCard--LpIZD{margin-bottom:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderPanel": "loaderPanel--AOOjt",
	"paper": "paper--18dhr",
	"selectCreditCard": "selectCreditCard--LpIZD"
};
export default ___CSS_LOADER_EXPORT___;
