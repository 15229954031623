import {connect} from 'react-redux';
import Payments from './index';
import NarrowPayments from './narrow';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {
  paymentProviderType,
  IStripeInfo,
} from 'app/services/client/client.types';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {PaymentActionsNS} from 'app/actions/payment/paymentActions';
import {sortBy} from 'lodash';
import {IGroupedTablesBoxItem} from '../GroupedTablesBox/types';
import {IVenue} from 'app/models';
import {IApplyPromoCode} from 'app/actions/booking/interfaces';
import {IBookingPayment} from "app/services/booking/booking.types";
import Switch from './switch';
import { IBookedByCustomerDetail, IBooking, IPaymentDetailsGenericData, IPrepareEwayData, IPrepareEwayFunctionData } from 'shared-types/index';


const NS = 'PaymentsContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {activeVenue, booking, appSettings, hasPromoCodes} = widget;

  const isStripe: boolean = activeVenue && activeVenue.paymentSettings
    ? activeVenue.paymentSettings.paymentProvider === paymentProviderType.stripe
    : false;
  const detailItems = (itemMap: IGroupedTablesBoxItem[], item: (IBookedByCustomerDetail | IBooking)) => {
    if (!item || !itemMap) {
      return []
    }
    return itemMap.reduce((acum, nameObj) => {
      const value = item[nameObj.name as keyof (IBookedByCustomerDetail | IBooking)];
      if (value) {
        return [
          ...acum, {
            name: nameObj.value,
            value,
            order: nameObj.order
          }
        ]
      }
      return acum
    }, []);
  }

  // maps props in customer to human friendly names
  const customerMap: IGroupedTablesBoxItem[] = [
    {order: 0, name: 'firstName', value: 'First Name'},
    {order: 1, name: 'lastName', value: 'Surname'},
    {order: 2, name: 'company', value: 'Company Name'},
    {order: 3, name: 'phone', value: 'Mobile'},
    {order: 4, name: 'email', value: 'Email'},
    {order: 10, name: 'notes', value: 'Requests'},
  ]

  const {customer, viewDate, viewTime, covers, payment, serviceName, bookedBy, isBookedBy} = booking;

  // to fix an issue where the customer notes were displayed instead of booking notes.
  if (customer) {
    customer.notes = (booking && booking.isFromDiary === false ? booking.notes : '');
  }
  const customerDetailItems: IGroupedTablesBoxItem[] = detailItems(customerMap, customer);

  const bookedByInfo: IGroupedTablesBoxItem[] = [
    {order: 0, name: 'firstName', value: 'First Name'},
    {order: 1, name: 'lastName', value: 'Surname'},
    {order: 2, name: 'company', value: 'Company Name'},
    {order: 3, name: 'phone', value: 'Mobile'},
    {order: 4, name: 'email', value: 'Email'},
  ]
  const bookedByDetailItems: IGroupedTablesBoxItem[] = isBookedBy ? detailItems(bookedByInfo, bookedBy) : [];

  return {
    theme: widget.theme,
    wrapperStyle: widget.wrapperStyle,
    stripeInstance: widget.stripe,
    isStripe,
    stripePublishableKey: isStripe ? (activeVenue.paymentSettings as IStripeInfo).publishableKey : null, // || 'pk_test_12345'
    venuePaymentSettings: activeVenue ? activeVenue.paymentSettings : null,
    currency: widget.activeVenue ? (widget.activeVenue as IVenue).currency : null,
    payment,
    footerNavShowCancel: appSettings.canDeleteBooking,
    isReuseCreditCardMode: !!widget.savedBooking
                && widget.activeVenue.widgetSettings.enableAdvancedEditingSupport
                && !!widget.savedBooking?.payment?.cardLast4, //Only allow editing credit cards if we have the last 4 digits of a previous payment available.
    cardLast4: widget.savedBooking?.payment?.cardLast4,
    isExistingBooking: !!widget.savedBooking && widget.activeVenue.widgetSettings.enableAdvancedEditingSupport,
    bookedByDetails: isBookedBy ? {
      heading: 'Booked by details',
      subtitle: '*All communications will be sent to this contact*',
      items: sortBy(bookedByDetailItems, 'order')
    } : null,
    customerDetails: {
      heading: isBookedBy ? 'Customer details' : 'Your details',
      items: sortBy(customerDetailItems, 'order')
    },
    bookingDetails: {
      heading: 'Booking details',
      items: [{
        name: 'Restaurant',
        value: activeVenue ? activeVenue.name : ''
      }, {
        name: 'Booking date',
        value: viewDate || ''
      }, {
        name: 'Time',
        value: viewTime || ''
      }, {
        name: 'Number of guests',
        value: covers.toString()
      }, {
        name: 'Service Name',
        value: serviceName
      }]
    },
    hasPromoCodes,
    isBookedBy
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handlePrepareEwayPayment: (): Promise<IPrepareEwayData | IPrepareEwayFunctionData> => {
      return dispatch(PaymentActionsNS.prepareEwayPayment());
    },
    handleEwayPaymentSubmit: (formEl: HTMLFormElement): Promise<void> => {
      return dispatch(PaymentActionsNS.submitEwayPayment(formEl));
    },
    handleConfirmState: () => {
      // just used for analytics
      dispatch(BookingActionsNS.handleConfirmState());
    },
    handleStripeLoaded: (stripe: stripe.Stripe) => {
      dispatch(BookingActionsNS.stripeLoaded(stripe));
    },
    handleStripePaymentSubmit: (
      card: stripe.elements.Element,
      token: stripe.Token,
      paymentDetails: IPaymentDetailsGenericData
    ): Promise<void> => {
      return dispatch(PaymentActionsNS.submitStripePayment(card, token, paymentDetails));
    },
    handleCancelBooking: () => {
      dispatch(BookingActionsNS.deleteBooking());
    },
    handlePromotionCode: (promotionCode: string): Promise<IApplyPromoCode> => {
      return dispatch(BookingActionsNS.applyPromoCode(promotionCode));
    },
    handleStripePaymentExistingCardSubmit() {
      return dispatch(PaymentActionsNS.submitStripePaymentExistingCard());
    },
    handleEwayPaymentExistingCardSubmit() {
      return dispatch(PaymentActionsNS.submitEwayPaymentExistingCard());
    }
  }

};

const PaymentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Switch as any);

export default PaymentsContainer;
