import React from 'react';
import style from './style.module.scss';
import { IServiceSelector } from './types';
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import classNames from 'classnames';
import { renderIf } from 'shared-services/react-utils-service';

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'ServiceSelector';

export default function ServiceSelector({scheduleMessage, activeServiceId, services, handleSelect, isStacked = false}: IServiceSelector) {

  const [value, setValue] = React.useState(activeServiceId || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const id: string = (event.target as HTMLInputElement).value;
    setValue(id);
    handleSelect(id);
  };

  // const isStacked = IframeResizerService.isStacked(wrapperStyle);
  // const isStacked = false;

  return (
    <div>

      {renderIf(scheduleMessage, (
        <Typography component="div" className={style.message}>
          <ReactMarkdown source={scheduleMessage} escapeHtml={false} />
        </Typography>
      ))}

      {renderIf(services.length, (
        <FormControl component="fieldset" className={classNames({
          // [style.radioWrap]: true,
          [style.radioWrapIsStacked]: isStacked
        })}>
          <RadioGroup aria-label="services" name="services"
            value={value}
            onChange={handleChange}
            className={classNames({
              [style.radGrp]: true,
              [style.radGrpIsStacked]: isStacked
            })}>
            {services.map((s, i) => (
              <FormControlLabel className={classNames({
                [style.radLbl]: true,
                [style.radLblIsStacked]: isStacked
              })} key={i} value={s.id} control={
                <Radio color="secondary" className={style.radBtn} checked={s.id === activeServiceId} />
              } label={s.name} />
            ))}
          </RadioGroup>
        </FormControl>
      ))}
    </div>
  )
}
