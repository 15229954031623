import React, { Component, Children } from 'react';
import { IProps } from './types';
import style from './style.module.scss';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TextField, Paper, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import isEqual from 'react-fast-compare';
import { IEwayInfo } from 'shared-types/index';
import { PaymentService } from 'shared-services/payment-service/index';
import classNames from 'classnames';
import CvcPopover from '../cvcPopover';
import { wrapperStyleType, themeTypes } from 'shared-types/index';
import LocationService from 'shared-services/location-service/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import { UseExistingCreditCardEnum } from './useExistingCreditCardEnum'
interface IFormData {
  name: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
}

interface IState {
  isCVCPopoverOpen: boolean;
  useExistingCreditCard: UseExistingCreditCardEnum;
}


const NS = 'PaymentDetailsGeneric';

class InnerForm extends Component<FormikProps<IFormData> & IProps & {children?: any}, IState> {

  cvcLink: any = React.createRef();

  readonly state: IState = {
    isCVCPopoverOpen: false,
    useExistingCreditCard: this.props.allowExistingCreditCard ? UseExistingCreditCardEnum.UseExistingCard : UseExistingCreditCardEnum.UseNewCard,
  }
  

  componentDidUpdate(prevProps: FormikProps<IFormData> & IProps) {
    if (!isEqual(prevProps.values, this.props.values) || !isEqual(prevProps.errors, this.props.errors)) {
      this.handleWholeFormChange();
    }
  }

  componentDidMount(){
    if (this.props.handleUseExistingCardChange) {
      //notify initial state of existing card selection
      this.props.handleUseExistingCardChange(this.state.useExistingCreditCard === UseExistingCreditCardEnum.UseExistingCard);
    }
  }
  
  /**
   * Handler for form as a whole
   */
  handleWholeFormChange = () => {
    const {name, cardNumber, expiryMonth, expiryYear, cvc} = this.props.values;
    const {isValid, handleUpdate} = this.props;
    
    handleUpdate({name, cardNumber, expiryMonth, expiryYear, cvc}, isValid);
  }

  /**
  * Manages state for handling new vs existing credit card selection
  */
    handleUseExistingCardChange(event: React.ChangeEvent<HTMLInputElement>) {
      const newValue = (event.target as HTMLInputElement).value as UseExistingCreditCardEnum;
      this.setState({ ...this.state, useExistingCreditCard: newValue })
      if (this.props.handleUseExistingCardChange) {
        this.props.handleUseExistingCardChange(newValue === UseExistingCreditCardEnum.UseExistingCard);
      }
    }

  render() {
    const {
      // custom props
  
      // formik props
      touched,
      errors,
      getFieldProps, // convenience props for values, handleChange, handleBlur
      wrapperStyle,
      theme,
      cvcImagePath
    } = this.props;

    const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
    const isTightFit = wrapperStyle === wrapperStyleType.standard;

    /**
     * iPhone's zoom into input text fields if they are less than 16px, so we device sniff here
     * and give Apple what it wants.
     */
    const useEnlargedFont: boolean = LocationService.detectPhone().isIPhone;

    return (
      <Paper elevation={1} className={classNames({
        [style.root]: true,
        [style.rootUseEnlargedFont]: useEnlargedFont,
      })} >
        {renderIf(this.props.allowExistingCreditCard, () => (
          <RadioGroup aria-label='existing-card' name="existing-card-group" className={style.selectCreditCard}
            value={this.state.useExistingCreditCard}
            onChange={event => this.handleUseExistingCardChange(event)}
           >
            <FormControlLabel value={UseExistingCreditCardEnum.UseExistingCard} control={<Radio />} label={`Update with existing card ending in ${this.props.existingCreditCardLast4}`} />
            <FormControlLabel value={UseExistingCreditCardEnum.UseNewCard} control={<Radio />} label="Pay with a new credit card" />
          </RadioGroup>
        ))}
        {renderIf(this.state.useExistingCreditCard === UseExistingCreditCardEnum.UseNewCard, () => (
        <form noValidate >
          <TextField
            data-testid="name-input"
            className={classNames({
              [style.formRow]: true,
              [style.formRowIsDark]: isDark
            })}
            id="name" name="name" label="Name"
            required fullWidth 
            {...getFieldProps('name')}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name ? errors.name : ''}
          />
          <TextField
            data-testid="card-number-input"
            className={classNames({
              [style.formRow]: true,
              [style.formRowIsDark]: isDark
            })}
            id="cardNumber" name="cardNumber" label="Card Number"
            required fullWidth 
            {...getFieldProps('cardNumber')}
            error={touched.cardNumber && Boolean(errors.cardNumber)}
            helperText={touched.cardNumber ? errors.cardNumber : ''}
          />
          <div className={classNames({
            [style.cvcAndExpiryWrap]: true,
            [style.formRow]: true,
            [style.formRowIsDark]: isDark,
            [style.cvcAndExpiryWrapIsTightFit]: isTightFit
          })}>
            <div className={style.expiryWrap}>
              <TextField
                data-testid="expiry-month-input"
                className={classNames({
                  [style.expiryMonth]: true,
                  [style.expiryMonthIsTightFit]: isTightFit
                })}
                id="expiryMonth" name="expiryMonth" label="MM"
                required fullWidth 
                {...getFieldProps('expiryMonth')}
                error={touched.expiryMonth && Boolean(errors.expiryMonth)}
                // helperText={touched.expiryMonth ? errors.expiryMonth : ''}
              />
              <span className={style.expirySlash}>/</span>
              <TextField
                data-testid="expiry-year-input"
                className={classNames({
                  [style.expiryYear]: true,
                  [style.expiryYearIsTightFit]: isTightFit
                })}
                id="expiryYear" name="expiryYear" label="YY"
                required fullWidth 
                {...getFieldProps('expiryYear')}
                error={touched.expiryYear && Boolean(errors.expiryYear)}
                // helperText={touched.expiryYear ? errors.expiryYear : ''}
              />
            </div>
            <div className={style.cvcWrap}>
              <TextField
                data-testid="cvc-input"
                className={classNames({
                  [style.cvc]: true,
                  [style.cvcIsTightFit]: isTightFit
                })}
                id="cvc" name="cvc" label="CVC"
                required fullWidth 
                {...getFieldProps('cvc')}
                error={touched.cvc && Boolean(errors.cvc)}
                // helperText={touched.cvc ? errors.cvc : ''}
              />
              <CvcPopover theme={this.props.theme} cvcImagePath={cvcImagePath} />
            </div>
          </div>

        </form>
        ))}
        {this.props.children}

      </Paper>
    );
  }
}



/**
 * Formik wrapper
 */
export const PaymentDetailsGeneric = withFormik({

  /**
   * Useful for style guide, so we can prepopulate fields and save some dev time,
   * but not good for actual implementation because we need to change the container's paymentDetails
   * with every key stroke, and we don't want that to reinitialize the form every time (because
   * when paymentDetails get's set to null, that would affect our user's input).git
   */
  enableReinitialize: location.pathname === '/style-guide',

  mapPropsToValues: ({paymentDetails}: IProps) => {

    const {name, cardNumber, expiryMonth, expiryYear, cvc} = paymentDetails || ({} as any); // empty object prevents null error
    
    // sets initial values in the form (must use empty strings, not null)
    const props = {
      name: name || '',
      cardNumber: cardNumber || '',
      expiryMonth: expiryMonth || '',
      expiryYear: expiryYear || '',
      cvc: cvc || '',
    };

    return props;
  },

  // Custom validation rules
  validate: (values: IFormData, {venuePaymentSettings}: IProps) => {
    const errors: any = {};

    const {acceptAmericanExpress} = venuePaymentSettings as IEwayInfo;
    
    const amexCardEntered: boolean = acceptAmericanExpress && PaymentService.isAmex(values.cardNumber);
    PaymentService.addCardNumberValidationErrors(amexCardEntered, values.cardNumber, errors);
    PaymentService.addCVCValidationErrors(amexCardEntered, values.cvc, errors);
    PaymentService.addExpiryValidationErrors(values.expiryMonth, 'Month', errors);
    PaymentService.addExpiryValidationErrors(values.expiryYear, 'Year', errors);

    return errors;
  },

  // auto validation rules
  validationSchema: Yup.object({
    name: Yup.string()
      .required('You forgot to enter your name'),
  }),

  // not using this, but it is mandatory property
  handleSubmit: (values, { setSubmitting }) => {}
})(InnerForm);
