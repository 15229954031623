// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--ShaZ8{margin:10px 0 12px;display:flex;justify-content:space-between;min-height:1.3rem}.line1--TRegO,.line2--IpooM{width:100%;height:1px;margin-top:.7rem;opacity:.7;background:#000}.line1IsDark--7LRd7,.line2IsDark--exVwF{background:#fff}.orText--f5Gfw{font-size:1.3rem;padding:0 10px;font-weight:bold;line-height:1;text-align:center;color:#000}.orTextIsDark--Czmny{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--ShaZ8",
	"line1": "line1--TRegO",
	"line2": "line2--IpooM",
	"line1IsDark": "line1IsDark--7LRd7",
	"line2IsDark": "line2IsDark--exVwF",
	"orText": "orText--f5Gfw",
	"orTextIsDark": "orTextIsDark--Czmny"
};
export default ___CSS_LOADER_EXPORT___;
