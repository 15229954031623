// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radGrp--84TWf{display:block}.radGrpIsStacked--et2rR{text-align:center}.radBtn--ul-DL{padding:3px 5px}.radLbl--FqJ\\+L{margin-right:18px;word-break:break-word}.radLblIsStacked--AnbAc{display:inline-block}.message--5fLd8{margin-bottom:10px}.radioWrapIsStacked--n0k3i{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radGrp": "radGrp--84TWf",
	"radGrpIsStacked": "radGrpIsStacked--et2rR",
	"radBtn": "radBtn--ul-DL",
	"radLbl": "radLbl--FqJ+L",
	"radLblIsStacked": "radLblIsStacked--AnbAc",
	"message": "message--5fLd8",
	"radioWrapIsStacked": "radioWrapIsStacked--n0k3i"
};
export default ___CSS_LOADER_EXPORT___;
