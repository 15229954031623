import React from 'react';
import { IGroupedTablesBox } from './types';
import style from './style.module.scss';
import { Paper, Typography, Divider, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { renderIf } from 'app/services/utils/utils.service';
import classNames from 'classnames';

const NS = 'GroupedTablesBox';

export default function GroupedTablesBox({ groups, children }: IGroupedTablesBox & {children?: any}) {
  return (
    <Paper elevation={1} className={style.paperBox}>

      {groups.map((group, i) => (
        <div key={`payment-conf-group-${i}`} className={style.group}>

          {renderIf(group.heading, () => (
            <>
            <Typography variant="h3" className={classNames({
                [style.heading]: !group.subtitle,
              })}>
                {group.heading}
              </Typography>
              {group.subtitle && <Typography className={classNames({
                [style.heading]: true,
                [style.bookedBySubHeading]: true,
              })}>
                {group.subtitle}
              </Typography>}

              <Divider className={style.divider} />
            </>
          ))}


          <Table aria-label="summary" size="small">
            <TableBody>
              {group.items.map((item, j) => !item.value ? null : (
                <TableRow key={`payment-conf-item-${j}`}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell >{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}

      {children}

    </Paper>
  )
}
