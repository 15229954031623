// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--3nOpP{padding:3px 12px}.link--V5oum a{color:#fff}.btn--IXzos{max-width:140px;margin-top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--3nOpP",
	"link": "link--V5oum",
	"btn": "btn--IXzos"
};
export default ___CSS_LOADER_EXPORT___;
