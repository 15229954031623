import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";
import {
  ISelectedMenuOption,
  IUpdateActiveChildMenuOptionPayload,
  IUpdateBookingOptionPaymentType, IUpdateCachedChildMenuOptionDetails
} from "app/actions/booking/interfaces";
import {IWidgetModel} from "app/models";
import {IBooking, IBookingMenuOptionExtrasUpdater} from "app/services/booking/booking.types";
import {IActionGen} from "app/types/common.types";
import {IActiveChildMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {IServicePaymentOption, IServicePaymentDetails} from "shared-types/index";

export default {
  [BookingOptionsActionsNS.Type.SELECTED_MENU_OPTION]: (state: IWidgetModel, action: ISelectedMenuOption): IWidgetModel => {
    const booking: IBooking = {
      ...state.booking,
      selectedMenuOptions: action.payload
    };

    return {
      ...state,
      booking,
      triedNext: null,
      blockNav: null
    }
  },

  [BookingOptionsActionsNS.Type.UPDATE_SELECTED_MENU_OPTION]: (state: IWidgetModel, action: ISelectedMenuOption): IWidgetModel => {

    const selectedMenuOption = state.booking.selectedMenuOptions.filter(s => !s.isUpsellItem);

    const newSelectedMenuOptions = [...selectedMenuOption, ...action.payload];

    const booking: IBooking = {
      ...state.booking,
      selectedMenuOptions: newSelectedMenuOptions,
      selectedUpsellOptions: action.payload
    };

    return {
      ...state,
      booking,
    }
  },

  [BookingOptionsActionsNS.Type.UPDATE_BOOKING_OPTION_PAYMENT_TYPE]: (state: IWidgetModel, action: IActionGen<IUpdateBookingOptionPaymentType>): IWidgetModel => {

    if (!state.activeService || !state.activeService.paymentDetails || !state.activeService.paymentDetails.options) {
      return {...state}
    }

    const options: IServicePaymentOption[] = state.activeService.paymentDetails.options.slice(); // creates a copy of the options array
    options.forEach(o => {
      o.paymentTypeOverride = o.id === action.payload.id ? action.payload.paymentTypeOverride : null;
    });

    const paymentDetails: IServicePaymentDetails = {
      ...state.activeService.paymentDetails,
      options
    };

    /**
     * Updates service with the price from booking options so it can be used to determine payment messaging.
     * This may seem a bit dodgy (replacing this service's price), but the original service price will always be
     * irrelevant (and should be zero) when a booking option exists.
     */
    if (action.payload.paymentTypeOverride) {
      paymentDetails.price = action.payload.paymentTypeOverride.amount;
    }

    return {
      ...state,
      activeService: {
        ...state.activeService,
        paymentDetails
      },
      blockNav: null
    }
  },

  [BookingOptionsActionsNS.Type.UPDATE_MENU_OPTION_EXTRAS]: (state: IWidgetModel, action: IActionGen<IBookingMenuOptionExtrasUpdater>): IWidgetModel => {

    const {parentId, implicitChildMenuOptions, explicitChildMenuOptions, isSameForAll} = action.payload;

    const selectedMenuOptions = [
      ...state.booking.selectedMenuOptions
    ];
    const parentBookingOption = selectedMenuOptions.find(o => o.menuOptionId === parentId);

    if (parentBookingOption) {
      const hasChildItems = implicitChildMenuOptions.length || explicitChildMenuOptions.length;

      if (hasChildItems) {
        parentBookingOption.extras = {
          implicitChildMenuOptions,
          explicitChildMenuOptions,
          isSameForAll
        };
      } else {
        delete parentBookingOption.extras;
      }
    }

    const booking: IBooking = {
      ...state.booking,
      selectedMenuOptions
    }

    return {
      ...state,
      booking
    }
  },

  [BookingOptionsActionsNS.Type.UPDATE_ACTIVE_CHILD_MENU_OPTION]: (state: IWidgetModel, action: IActionGen<IUpdateActiveChildMenuOptionPayload>): IWidgetModel => {

    const menuOptionsImplicit = action.payload ? action.payload.implicitChildMenuOptions : null;
    const menuOptionsExplicit = action.payload ? action.payload.explicitChildMenuOptions : null;

    const activeChildMenuOption: IActiveChildMenuOption = {
      ...state.activeChildMenuOption,
      menuOptionsImplicit,
      menuOptionsExplicit
    };

    // Here we update `state.cachedMenuOptionDetails` incase we need to request this menu option again (saves unnecessary backend calls)
    const cachedMenuOptionDetails = [
      ...state.cachedMenuOptionDetails
    ];
    (menuOptionsImplicit || []).concat(menuOptionsExplicit || []).forEach(opt => {
      const ind: number = cachedMenuOptionDetails.findIndex(({id}) => id === opt.id);
      if (ind > -1) {
        cachedMenuOptionDetails[ind] = opt;
      } else {
        cachedMenuOptionDetails.push(opt);
      }
    })

    return {
      ...state,
      activeChildMenuOption,
      cachedMenuOptionDetails
    }
  },

  [BookingOptionsActionsNS.Type.UPDATE_CACHED_MENU_OPTION_DETAILS]: (state: IWidgetModel, action: IUpdateCachedChildMenuOptionDetails): IWidgetModel => {

    // Here we update `state.cachedMenuOptionDetails` incase we need to request this menu option again (saves unnecessary backend calls)
    const cachedMenuOptionDetails = action.payload.fullRefresh ? [] : [
      ...state.cachedMenuOptionDetails
    ];
    action.payload.options.forEach(opt => {
      const ind: number = cachedMenuOptionDetails.findIndex(({id}) => id === opt.id);
      if (ind > -1) {
        cachedMenuOptionDetails[ind] = opt;
      } else {
        cachedMenuOptionDetails.push(opt);
      }
    });

    return {
      ...state,
      cachedMenuOptionDetails
    }
  },
}
