// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--AiDxl{display:block}.containerIsLandscape--dPIQ0{display:flex}.rightColumn--OKPXy,.leftColumn--isNob{padding-bottom:12px}.rightColumnIsLandscape--4TdTJ,.leftColumnIsLandscape--\\+cAnx{padding-bottom:0}.rightColumnDoGrow--KaE0B,.leftColumnDoGrow--1L2-W{flex-grow:1}.rightColumnIsWide--uIL4D,.leftColumnIsWide--bwkJQ{width:50%}.rightColumnDoGrow--KaE0B,.rightColumnIsWide--uIL4D{padding-left:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--AiDxl",
	"containerIsLandscape": "containerIsLandscape--dPIQ0",
	"rightColumn": "rightColumn--OKPXy",
	"leftColumn": "leftColumn--isNob",
	"rightColumnIsLandscape": "rightColumnIsLandscape--4TdTJ",
	"leftColumnIsLandscape": "leftColumnIsLandscape--+cAnx",
	"rightColumnDoGrow": "rightColumnDoGrow--KaE0B",
	"leftColumnDoGrow": "leftColumnDoGrow--1L2-W",
	"rightColumnIsWide": "rightColumnIsWide--uIL4D",
	"leftColumnIsWide": "leftColumnIsWide--bwkJQ"
};
export default ___CSS_LOADER_EXPORT___;
