// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--CZcmL{margin-top:15px}.rootIsCenetered--r7e1R{text-align:center}.text--OqF-7{font-size:1.4rem;margin-bottom:7px}.text--OqF-7:last-child{margin-bottom:0}.markdownText--VXpVQ{line-height:1.3}.markdownText--VXpVQ p{margin:0 0 7px 0}.markdownText--VXpVQ ul,.markdownText--VXpVQ ol{padding-left:25px}.markdownText--VXpVQ ul,.markdownText--VXpVQ ol{padding-left:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--CZcmL",
	"rootIsCenetered": "rootIsCenetered--r7e1R",
	"text": "text--OqF-7",
	"markdownText": "markdownText--VXpVQ"
};
export default ___CSS_LOADER_EXPORT___;
