import React, { Component } from 'react';
import { IMiniToast } from './types';
import style from './style.module.scss';
import { Fade, Paper, Typography } from '@material-ui/core';
import { IWidgetTheme } from 'app/models';
import { withStyles } from '@material-ui/styles';

const NS = 'MiniToast';

const themeStyles = (theme: IWidgetTheme) => ({
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.dark}`
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.dark}`
  }
});

interface IState {
  tempMessages: string[];
}

class MiniToast extends Component<IMiniToast & {classes: any}, IState> {

  readonly state: IState = {
    tempMessages: null
  }
  
  private to: number;

  constructor(props: IMiniToast & {classes: any}) {
    super(props);
    this.clearTempMessages = this.clearTempMessages.bind(this);
  }

  private clearTempMessages(): void {
    this.setState({
      tempMessages: null
    });
  }

  /**
   * Sets a timeout for the toast messages to disappear, using callback so 
   * state can be handled externally.
   */
  private setFadeOutTO(): void {
    const {timeout, messages, handleMessageChanges} = this.props;

    if (this.to) {
      window.clearTimeout(this.to);
    }
    this.to = window.setTimeout(() => {
      this.setState({tempMessages: messages.slice()});
      handleMessageChanges(['', '']);
    }, timeout);
  }

  render() {
    const {classes, messages} = this.props;
    const doOpen: boolean = messages.some(m => !!m);

    if (doOpen) {
      this.setFadeOutTO();
    }

    return (
      <Fade in={doOpen} onEnter={this.clearTempMessages} onExited={this.clearTempMessages} >
        <div className={style.summaryMsgContainer}>
          {/* tempMessages used so fade out happens smoothly */}
          {(this.state.tempMessages || messages).map((m,i) => (
            m ? 
            <Paper key={i} className={[style.popperContent, classes.warning].join(' ')}>
              <Typography className={style.popperText} >{m}</Typography>
            </Paper>
            : ''
          ))}
        </div>
      </Fade>
    )
  }
}

export default withStyles(themeStyles)(MiniToast);