import { connect } from 'react-redux';
import ServiceMessage from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import {IDispatchFromProps, IOwnProps, IStateFromProps} from './types';
import { ThunkDispatch } from 'redux-thunk';
import { BookingService } from 'app/services/booking/booking.service';
import { IVenue } from 'app/models';
import { TimeFilterService } from 'app/services/timeFilter/timeFilter.service';
import {cloneDeep} from "lodash";
import {PricingTypes, IServicePaymentDetails} from "shared-types/index";

const NS = 'ServiceMessageContainer';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IStateFromProps & IOwnProps => {
  const activeService = widget.activeService;

  let hasTimes = false;
  if (activeService) {
    const filteredTimes = TimeFilterService.getFilteredTimesNonMutated(
      activeService.times,
      true,
      widget.activeSection
    );
    // note: this will omit expired times, but will still include disabled times (from blockouts and other bookings)
    hasTimes = !!filteredTimes.length;
  }

  return {
    wrapperStyle: widget.wrapperStyle,
    activeService,
    hasTimes,
    isNabActive: ownProps.isNabActive
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {

  }
};

const ServiceMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceMessage as any);

export default ServiceMessageContainer;
