import React from "react";
import {IMenuOptionPaymentType} from "../types";
import {renderIf} from "shared-services/react-utils-service/index";
import {Typography} from "@material-ui/core";
import style from "../narrow/menuOptionNarrow.style.module.scss";
import {SimplePaletteColorOptions} from "@material-ui/core/styles";
import {PricingTypes, servicePaymentType} from "shared-types/index";

export const MenuOptionPaymentType: React.FC<IMenuOptionPaymentType> = ({
  theme, paymentTypeError, paymentType, bgColor, pricingType,
  extrasHasPayment, currencyValue, bookingCancellationTimeWindowInHours, hoursText
}: IMenuOptionPaymentType & { children?: any }) => {

  return (
    <>
      {renderIf(paymentTypeError, () => (
        <Typography
          data-testid="payment-calc-error-message"
          variant="body1"
          className={style.paymentText}
          style={{color: (theme.palette.error as SimplePaletteColorOptions).main}}
        >
          Unable to calculate payment type. Check connection.
        </Typography>
      ), () => (
        renderIf(paymentType && pricingType, (
          <Typography variant="body1" className={style.paymentText} data-testid="payment-text">
            {
              (() => {
                switch (paymentType) {
                  case servicePaymentType.preAuth:
                    return <>
                      A Booking Guarantee of {currencyValue} is required per {pricingType === PricingTypes.PerPax ? 'person' : 'booking'}.
                    </>;

                  case servicePaymentType.fullPayment:
                    return <>A full payment is required per {pricingType === PricingTypes.PerPax ? 'person' : 'booking'}.</>;

                  case servicePaymentType.deposit:
                    return <>A deposit is required per {pricingType === PricingTypes.PerPax ? 'person' : 'booking'}.</>;

                  case servicePaymentType.noPayment:
                    return extrasHasPayment ? <></> : <>No payment is required.</>;

                  default:
                    return <></>
                }
              })()
            }
          </Typography>
        ))
      ))}
    </>
  )

}
