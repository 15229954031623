import React, {ComponentType, useState, useEffect,  useMemo} from 'react';
import {IRobotValidationModalDialog} from './types';
import style from './style.module.scss';
import MuiDialog, {DialogProps} from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/styles";
import {wrapperStyleType} from "app/models";
import appValues from "app/constants/appValues";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import ReCaptchaV2 from 'react-google-recaptcha';

const NS = 'RobotValidationModalDialog';
export default function RobotValidationModalDialog({wrapperStyle, doShow, modalMsg, handleConfirm, handleCancel, confirmText, cancelText, modalHeader}: IRobotValidationModalDialog) {
  const [isVerify, setIsVerified] = useState<boolean>(false);
  const handleToken = () => {
    setIsVerified(true);
  }

  useEffect(() => {
    setIsVerified(false);
  }, []);

  const Dialog: ComponentType<DialogProps> = withStyles({
    paper: {
      minWidth: (() => {
        switch (wrapperStyle) {
          case wrapperStyleType.standard:
            return `${appValues.STACKED_BP - 20}px`;

          case wrapperStyleType.wide:
            return `600px`;
        }

        return null;
      })()
    }
  })(MuiDialog);

  const MemoDialog = useMemo(() => Dialog, [wrapperStyle]);

  return (
    <MemoDialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: style.paper,
      }}
      maxWidth="md"
      open={doShow}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogContent
        classes={{
          root: style.content
        }} data-testid="dialog-content">

        <DialogContentText id="alert-dialog-description" component="div" className={style.markdownText}>
          <Typography variant="h3">
            {modalMsg}
          </Typography>
        </DialogContentText>
        <ReCaptchaV2
          data-testid="recaptcha-widget"
          sitekey= {appValues.RECAPTCHA_SITE_KEY}
          onChange={handleToken}
        />

      </DialogContent>
      <DialogActions classes={{
        root: style.btnWrap
      }}>
        <Button onClick={()=>
         {
            setIsVerified(false);
            handleCancel();
          }} color="default" autoFocus variant="contained">
          {cancelText || 'Cancel'}
        </Button>

        <Button onClick={handleConfirm} disabled={!isVerify} color="primary" autoFocus variant="contained">
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </MemoDialog>
  )
}
