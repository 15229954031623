// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column2--Mcibi{height:100%;display:flex;flex-direction:column;justify-content:space-between}.progressStepper--CJKZT{max-width:700px;margin:0 auto;padding-left:20px;padding-right:20px}.agreeText--ptaK2{margin-top:30px;line-height:1.3}.stripeWrapIsHidden--FYhBo{visibility:hidden;position:absolute;height:0;width:0}.detailsSummaryTable--oj\\+84{margin-bottom:15px}.narrowContainer--JQocb>*{margin-bottom:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column2": "column2--Mcibi",
	"progressStepper": "progressStepper--CJKZT",
	"agreeText": "agreeText--ptaK2",
	"stripeWrapIsHidden": "stripeWrapIsHidden--FYhBo",
	"detailsSummaryTable": "detailsSummaryTable--oj+84",
	"narrowContainer": "narrowContainer--JQocb"
};
export default ___CSS_LOADER_EXPORT___;
