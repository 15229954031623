// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".summaryMsgContainer--LCMmY{position:absolute;top:5px;right:5px;display:flex;flex-direction:column;align-items:flex-end;justify-content:center}.popperContent--5ksdh{padding:2px 5px;margin-top:3px}.popperContent--5ksdh:first-child{margin-top:0}.popperText--vFqaP{font-size:.9rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryMsgContainer": "summaryMsgContainer--LCMmY",
	"popperContent": "popperContent--5ksdh",
	"popperText": "popperText--vFqaP"
};
export default ___CSS_LOADER_EXPORT___;
