// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rightColumnInner--iwlGD{display:flex;flex-direction:column;justify-content:space-between;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightColumnInner": "rightColumnInner--iwlGD"
};
export default ___CSS_LOADER_EXPORT___;
