import React from 'react';
import { IMenuOptionsExtras, IMenuOptionsExtrasItem } from './types';
import style from './style.module.scss';
import { Button, Typography, Table, TableBody, TableRow, TableCell, withStyles, IconButton } from '@material-ui/core';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { IntlService } from 'shared-services/intl-service/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import classNames from 'classnames';

const NS = 'MenuOptionsExtras';

const ExpansionPanel = withStyles({
  root: {
    maxWidth: '300px',
    width: '100%',
    '&.Mui-expanded': {
      margin: '12px 0'
    }
  },
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '4px 9px 3px 9px',
    backgroundColor: 'rgba(0,0,0,0.04)',
    display: 'block'
  },
})(MuiExpansionPanelDetails);


const ExpansionPanelSummary = withStyles({
  root: {
    padding: '0 4px',
    minHeight: '30px',
    '&.Mui-expanded': {
      minHeight: '30px'
    }
  },
  content: {
    alignItems: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  expandIcon: {
    padding: '6px 2px',
    marginRight: 0
  }
})(MuiExpansionPanelSummary);


export default function MenuOptionsExtras({ handleOpen, items, currency, parentMenuOptionId, isDesignV2 }: IMenuOptionsExtras) {

  const total: number = items
    ? items.reduce((acc: number, item: IMenuOptionsExtrasItem) => {
        return acc + (item.price * item.quantity);
      }, 0)
    : 0;

  return (
    <div className={style.root}>

      {renderIf(items, () => (
        <ExpansionPanel className={classNames({
          [style.designV2]: isDesignV2
        })}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <IconButton color="primary"
              aria-label="edit"
              component="span"
              size="small"
                        data-testid="add-extras-icon"
              className={style.editIconBtn}
              onClick={(evt: React.MouseEvent) => {
                evt.stopPropagation(); // stops the expansionPanel opening
                handleOpen();
              }}
            >
              <EditIcon />
            </IconButton>
            <Typography className={style.extrasBtnText} data-testid="extras-price" >
              {renderIf(total, () => (
                <>Extras: {IntlService.currencyValue(total, currency)}</>
              ), () => (
                <>Extras</>
              ))}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table aria-label="summary" size="small"  className={style.table} data-testid="extras-price-details">
              <TableBody>
                {items.map(({label, id, quantity, price}, i) => (
                  renderIf(quantity > 0, (
                    <TableRow key={i}>
                      <TableCell>{label} x {quantity}:&nbsp;</TableCell>
                      <TableCell>{IntlService.currencyValue(price * quantity, currency)}</TableCell>
                    </TableRow>
                  ))
                ))}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ), () => (
        <Button onClick={handleOpen}
          variant="contained"
          color="primary"
          aria-label="add"
          data-testid="add-extras-btn"
          size="small" >
          <AddIcon className={style.addIcon} /> Add Extras
        </Button>
      ))}

    </div>
  )
}
