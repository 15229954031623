// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column2--d3-IH{height:100%;display:flex;flex-direction:column;justify-content:space-between}.paperBox--efFD7{display:flex;flex-direction:column;justify-content:space-between}.paperBox1--pTTtX{height:100%;padding:15px 15px 25px}.paperBox2--wyC\\+L{padding:15px;margin-bottom:15px}.paperBox2IsLandscapeHasTags--A8DRK{flex-grow:1}.detailsTitle--t2pjw{margin:7px 6px 10px}.specialWrap--z9wd2{width:100%;margin-bottom:3px}.tagWrap--Vu6Wp{margin-top:10px;display:flex;flex-flow:column;max-width:450px;vertical-align:middle}.tagWrap--Vu6Wp>span,.tagWrap--Vu6Wp div{display:flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin-right:7px;margin-bottom:7px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.summaryTable--Sh5kf{overflow:hidden;white-space:nowrap}.summaryTable--Sh5kf td:last-child{padding-left:5px;padding-right:3px;max-width:215px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.summaryTableIsStacked--v3htW td:first-child,.summaryTableIsStandard--znr3o td:first-child{padding-left:3px;padding-right:5px}.summaryTableIsStacked--v3htW td:last-child,.summaryTableIsStandard--znr3o td:last-child{padding-left:5px;padding-right:3px}.summaryTableIsStandard--znr3o td:last-child{max-width:170px}.summaryTableIsStacked--v3htW td:last-child{max-width:190px}.summaryTableIsNarrow--7U2\\+I td:last-child{max-width:150px}.summaryBookedByTips--j-5CW{margin:-5px 8px 10px}.summaryBookedByTipsColor--79nIN{color:#992662}.summaryBookedByTopMargin--0xZiG{margin-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column2": "column2--d3-IH",
	"paperBox": "paperBox--efFD7",
	"paperBox1": "paperBox1--pTTtX",
	"paperBox2": "paperBox2--wyC+L",
	"paperBox2IsLandscapeHasTags": "paperBox2IsLandscapeHasTags--A8DRK",
	"detailsTitle": "detailsTitle--t2pjw",
	"specialWrap": "specialWrap--z9wd2",
	"tagWrap": "tagWrap--Vu6Wp",
	"summaryTable": "summaryTable--Sh5kf",
	"summaryTableIsStacked": "summaryTableIsStacked--v3htW",
	"summaryTableIsStandard": "summaryTableIsStandard--znr3o",
	"summaryTableIsNarrow": "summaryTableIsNarrow--7U2+I",
	"summaryBookedByTips": "summaryBookedByTips--j-5CW",
	"summaryBookedByTipsColor": "summaryBookedByTipsColor--79nIN",
	"summaryBookedByTopMargin": "summaryBookedByTopMargin--0xZiG"
};
export default ___CSS_LOADER_EXPORT___;
