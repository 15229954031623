import { connect } from 'react-redux';
import Venues from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps, ISimpleVenue } from './interfaces';
import { ThunkDispatch } from 'redux-thunk';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';

const NS = 'VenuesContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {accountDetails, theme, triedNext, wrapperStyle} = widget;
  const venuesData = widget.venues;
  const {accountPhone, businessName} = accountDetails;

  const venuesList: ISimpleVenue[] = accountDetails.ownedVenues.reduce((acc: ISimpleVenue[], v) => {
    if (v.active && v.widgetSettings && v.widgetSettings.allowOnlineBookings) {
      const {id, logoUrl, name} = v;
      acc.push({id, logoUrl, name});
    }
    return acc;
  }, []);

  return {
    selectedVenueId: venuesData.selectedVenueId,
    theme,
    hasAccountDetails: !!accountDetails,
    venuesList,
    accountPhone,
    businessName,
    wrapperStyle,
    triedNext
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleVenueClick: (venue: ISimpleVenue) => {
      dispatch(NavigationActionsNS.changedVenue(venue.id));
    }
  }
};

const VenuesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Venues as any);

export default VenuesContainer;
