import { connect } from 'react-redux';
import PaymentMessage from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps, IOwnProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { IVenue } from 'app/models';
import { IBookingPayment } from 'app/services/booking/booking.types';
import {BookingService} from "app/services/booking/booking.service";
import {PricingTypes, IServicePaymentDetails} from "shared-types/index";

const NS = 'PaymentMessageContainer';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IStateFromProps & IOwnProps => {

  // if booking was opened via the link generated in the diary
  const isFromDiary: boolean = widget.booking && widget.booking.isFromDiary || false;

  const isUpsellPresent = widget.isUpsellPresent;

  const originalPaymentDetails = widget.originalPaymentDetails;

  const paymentDetails: IServicePaymentDetails | IBookingPayment = ownProps.useActiveService
    ? widget.activeService && widget.activeService.paymentDetails || null
    : widget.booking && widget.booking.payment || null;
  const isPricingPerBooking = (paymentDetails.pricingType && paymentDetails.pricingType == PricingTypes.PerBooking);
  return {
    hasMenuOptions: widget.booking && BookingService.checkForMenuOptionQuantity(widget.booking.selectedMenuOptions),
    paymentType: paymentDetails ? paymentDetails.paymentType : null,
    price: paymentDetails ? paymentDetails.price : null,
    currency: widget.activeVenue ? (widget.activeVenue as IVenue).currency : null,
    covers: widget.booking ? widget.booking.covers : 0,
    dividePriceByCovers: !ownProps.useActiveService || isUpsellPresent,
    isPricePerPerson: !isFromDiary && !isPricingPerBooking,
    bgColor: widget.appSettings.bgColor,
    preAuthorisationMessage: widget.activeVenue.widgetSettings.preAuthorisationMessage,
    theme: widget.theme,
    originalPaymentDetails
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {

  }
};

const PaymentMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMessage as any);

export default PaymentMessageContainer;
