import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import {IActionGen} from "app/types/common.types";
import {blockNavType, IStandbyData, IWidgetModel} from "app/models";
import {IBooking} from "app/services/booking/booking.types";
import {BookingService} from "app/services/booking/booking.service";

const NS = 'changedStandbyMode';

export default {
  [StandbyActionsNS.Type.CHANGED_STANDBY_MODE]: (state: IWidgetModel, {payload}: IActionGen<boolean>): IWidgetModel => {

    let standbyData: IStandbyData = state.standbyData;
    let booking: IBooking = state.booking;

    if (payload) { // clear booking and set standbyData
      standbyData = standbyData ? {
        ...standbyData
      } : null;

      standbyData = BookingService.getStandbyDataFromBooking(booking, standbyData);
    } else { // clear standbyData and set booking
      booking = {
        ...BookingService.getBookingFromStandbyData(standbyData, booking),
        viewTime: null
      }
      standbyData = null;
    }

    const availableTimes = state.filteredTimes?.filter(t => !t.isDisabled && !t.isBlocked);
    const blockNav = !standbyData && !availableTimes?.length ? blockNavType.becauseNoAvailableTimes : state.blockNav;

    return {
      ...state,
      booking,
      standbyData,
      blockNav
    }
  }
}
