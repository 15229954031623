import React from 'react';
import style from './style.module.scss';
import { IAvailableTimeListProps } from './types';
import { renderIf } from 'app/services/utils/utils.service';
import { Chip, Grid, Typography, withStyles } from '@material-ui/core';

const NS = 'AvailableTimeList';

const StyleChip = withStyles({
  label: {
    paddingLeft: 4,
    paddingRight: 4
  }
})(Chip);

/** Displays the available times in a chip */
export const AvailableTimeList = ({ times, isStandbyMode, handleSelect, gridColumns, isFlexibleTime, timeNotAvailableMessage, nabAvailableServices }: IAvailableTimeListProps) => {
  return (
    <>
     {renderIf( nabAvailableServices && nabAvailableServices.length > 0, ()=>( 
        <Typography>
          Time available for service(s) <strong>{nabAvailableServices.map(s=> s.name).join(", ")}</strong>.
        </Typography>))}
     
      <Grid container >
        {times && times.filter((t => t.isDisabled == false || (isStandbyMode && !t.isBlocked))).map((time, index) => {
          const showMsg: boolean = !isStandbyMode && time.isDisabled && !time.expired && !!timeNotAvailableMessage;
          return (
            <Grid key={index} item xs={gridColumns} >
              <div className={style.timeBox} data-e2e-available-time={time.name} >
                <StyleChip
                  className={style.timeChip}
                  label={time.name}
                  clickable
                  disabled={!isStandbyMode && time.isDisabled || isFlexibleTime}
                  color={time.isSelected ? 'primary' : 'default'}
                  variant={time.isSelected ? 'default' : 'outlined'}
                  onClick={() => handleSelect(time)}
                />
                {renderIf(showMsg, () => (
                  <Typography variant="body1" className={style.timeNotAvailMsg} data-e2e-time-not-available={time.name}>
                    {timeNotAvailableMessage}
                  </Typography>
                ))}
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>

  )
}
