// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--DAiaG{height:100%;background-color:#f3f3f3;padding:12px 11px 13px}.rootIsDark--gPzXe{background-color:rgba(255,255,255,.11)}.rootHasCb--4vimU{padding:16px 4px}.root--DAiaG>*{margin-top:8px}.root--DAiaG>*:first-child{margin-top:2px}.contentWrap--HF8Vk{display:flex;justify-content:center}.price--kQZ8N{margin-top:7px;margin-bottom:8px}.priceHasCb--uZcHx{margin-top:3px;margin-bottom:4px}.link--oQYQI{word-break:break-all}.linkHasCb--TOqZ4{margin-top:0;margin-bottom:4px}.label--prevc pre,.description--JfunR pre{white-space:pre-wrap}.description--JfunR,.paymentText--P7MlD{font-size:1.3rem;line-height:1.3}.label--prevc{line-height:1.3}.labelHasCb--TbX43{line-height:1.2;position:relative}.labelIsStacked--rYRpu{font-size:1.5rem}.description--JfunR{max-height:105px;overflow:auto;display:block}.descriptionHasCb--FOV-s{margin-top:0}.description--JfunR ul{text-align:left;padding-left:30px}.description--JfunR p{margin:2px 0 0}.descriptionDesignV2--puWhS{color:#858589}.checkbox--526wQ{padding:0}.designV2Container--rn9nP{background-color:#fff;display:flex;flex-direction:column;padding:10px;border:1px #fff solid}.designV2Description--1IfJE{display:flex;flex-direction:column}.designV2Children--wzTEa{display:flex;flex-direction:row;justify-content:space-between;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--DAiaG",
	"rootIsDark": "rootIsDark--gPzXe",
	"rootHasCb": "rootHasCb--4vimU",
	"contentWrap": "contentWrap--HF8Vk",
	"price": "price--kQZ8N",
	"priceHasCb": "priceHasCb--uZcHx",
	"link": "link--oQYQI",
	"linkHasCb": "linkHasCb--TOqZ4",
	"label": "label--prevc",
	"description": "description--JfunR",
	"paymentText": "paymentText--P7MlD",
	"labelHasCb": "labelHasCb--TbX43",
	"labelIsStacked": "labelIsStacked--rYRpu",
	"descriptionHasCb": "descriptionHasCb--FOV-s",
	"descriptionDesignV2": "descriptionDesignV2--puWhS",
	"checkbox": "checkbox--526wQ",
	"designV2Container": "designV2Container--rn9nP",
	"designV2Description": "designV2Description--1IfJE",
	"designV2Children": "designV2Children--wzTEa"
};
export default ___CSS_LOADER_EXPORT___;
